import { Grid, InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';
import { useEffect, useState } from 'react';

const FormInputNumber = ({ name, data, disabled, index, refetch,withGridBox, gridSize, ...props }) => {
  const [hidden, setHidden] = useState(false);

  const { control, watch, setValue } = useFormContext();

  let watchParent;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);


  const onChange = (e) => {
    // alert()
    // const value= e.target.value
    const afterChange = data?.options?.afterChange;
    if (afterChange) {
      const childs = Object.values(data?.options?.childs || {});
      if (afterChange.by === 'parent' && childs?.length) {
        const is = true;
        if (is) {
          if (afterChange.type === 'resetChilds') {
            childs?.map((x) => setValue(x?.name, null));
          }
        }
      }
    }
  };
  useEffect(() => {
    if (!watchParent) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on;
      if (afterChangeParent.by === 'child') {
        if (is) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
      }
    }
  }, [watchParent]);
  console.log({ data }, data?.label, '* * * FormInputNumber');
  if (hidden) return <></>;

  return (
    withGridBox ?
      <Grid item {...gridSize}>
        <RHFTextField
          type={'number'}
          name={name}
          label={data?.label}
          disabled={disabled}
          required={data?.options?.required}
          min={data?.options?.min}
          max={data?.options?.max}
          onChange={onChange}
          inputProps={{
            autoComplete: 'off',
            ...(data?.need_confirm //&& isHistory
              ? {
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <AcceptOrRejectWithQuestionsWithReason
                      {...data?.client_inputs?.[index || 0]}
                      history={data?.old_client_inputs?.[index || 0]}
                      type={data?.type}
                      refetch={refetch}
                    />
                  </InputAdornment>
                ),
              }
              : {}),
          }}
          {...data?.options?.inputProps}
        />
      </Grid>
      :
      <RHFTextField
        type={'number'}
        name={name}
        label={data?.label}
        disabled={disabled}
        required={data?.options?.required}
        min={data?.options?.min}
        max={data?.options?.max}
        onChange={onChange}
        inputProps={{
          autoComplete: 'off',
          ...(data?.need_confirm //&& isHistory
            ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason
                    {...data?.client_inputs?.[index || 0]}
                    history={data?.old_client_inputs?.[index || 0]}
                    type={data?.type}
                    refetch={refetch}
                  />
                </InputAdornment>
              ),
            }
            : {}),
        }}
        {...data?.options?.inputProps}
      />
  );
};

export default FormInputNumber;
