/* eslint-disable */
import { Box, Chip } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formTypesObject } from 'src/enumeration';
import createQueryString from 'src/utils/createQueryString';
import { fDate } from 'src/utils/formatTime';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';

const ShowFilters = ({ queryString , CustomFilterObj , setCustomFilterObj , stateMode = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: createQueryString(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  const data = useMemo(() => {
    let newFilters;
    try {
      if(stateMode){
        newFilters = Object.values(CustomFilterObj?.filters);;
        return newFilters;

      }
      newFilters = JSON.parse(queryString?.filters || '');
      return newFilters;
    } catch (error) {}
    console.log({ newFilters, queryString });
    if (!newFilters) return;
  }, [queryString]);
  // console.log('* * * ShowFilters : ', { data });
  const onDelete = (data, index) => {
    // console.log('* * * onDelete : ', { data });

    let newFilters;
    try {
      if(stateMode){
        newFilters = Object.values(CustomFilterObj?.filters);;
        newFilters = newFilters?.filter((_, i) => index != i);

        // console.log('* * * onDelete : ', { newFilters , data });

        const helpFilter = CustomFilterObj?.filters;
        
        delete helpFilter[data?.filterName];
        
        if(data?.afterDelete){
          delete helpFilter[data?.afterDeleteKey];
        }

        if(
          helpFilter?.hamsar_name ||
          helpFilter?.hamsar_last_name ||
          helpFilter?.hamsar_code_melli ||
          helpFilter?.hamsar_phone ||
          helpFilter?.hamsar_father_name ||
          helpFilter?.hamsar_gender ||
          helpFilter?.hamsar_passport_status ||
          helpFilter?.hamsar_nezam_vazife_status ||
          helpFilter?.hamsar_need_vam ||
          helpFilter?.hamsar_student_marriage ||
          helpFilter?.hamsar_university_city ||
          helpFilter?.hamsar_university_category ||
          helpFilter?.hamsar_university_name ||
          helpFilter?.hamsar_konkoor ||
          helpFilter?.hamsar_education_level ||
          helpFilter?.hamsar_is_student
      ){
        helpFilter.hamsar_nesbat_to_head = {
            value : "همسر",
            hidden : true
        };
      }else{
        delete helpFilter.hamsar_nesbat_to_head
      }

        setCustomFilterObj({
          ...CustomFilterObj,
          filters : helpFilter
        })
        return newFilters;
      }
      newFilters = JSON.parse(queryString?.filters || '');
      newFilters = newFilters?.filter((_, i) => index != i);
      console.log('* * * onDelete : ', { newFilters });
      navigateUrl({ filters: newFilters?.length ? JSON.stringify(newFilters) : '' });
      return newFilters;
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {data?.map((x, i) => {
        const isDate = x?.type === formTypesObject.DATE.value;
        const isLocation = x?.type === formTypesObject.LOCATION.value;

        if(x?.hidden) return <></>

        return (
          <Chip
            label={
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box> {`${x?.label}`}</Box>:<Box>{`${isDate ? fDate(x.value) : x?.title || x?.value}`}</Box>
              </Box>
            }
            onDelete={() => onDelete(x, i)}
          />
        );
      })}
    </Box>
  );
};

export default ShowFilters;
