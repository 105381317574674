import { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal, Stack } from '@mui/material';
import { hasAccess } from 'src/permission/utiles';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import ModalLayout from 'src/components/ModalLayout';
import UniversityExcelModal from '../excelModal';
import ProvinceSelector from '../../../components/selector/Province';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import universityCategorySelector from '../../universityCategory/selector';


const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const [SearchParams] = useSearchParams();
  const queryString = useQueryString({ sort: 'createdAt@desc' });
  const ModalLayoutQuestionInputRef = useRef();


  const handleQuestionModal = () => ModalLayoutQuestionInputRef.current.show((p) => !p);


  const methods = useForm({
    defaultValues: {
      "iran_province_id": null,
      "category_id": null,
    }
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;


  const checkAccess = useMemo(() => {
    return {
      update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
      read: hasAccess(access?.read),
    };
  }, [access]);
  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${Enum?.api?.base}`, { params: { ...queryString, travel_id: queryParams.travel } });

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_list`,
    url: getApi,
    params: { ...queryString },
    enabled: checkAccess.read,
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });

  const title = `${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  console.log({ tableOptions, data });

  return (
    <Page title={`${title}`}>

      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        <UniversityExcelModal
          onClose={handleQuestionModal}
        />
      </ModalLayout>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.create && (
              <>
                <Stack direction={"row"} gap={1}>
                  {/* <Button
                    variant="outlined"
                    color={'success'}
                    onClick={handleQuestionModal}
                    // component={RouterLink}
                    // to={`${Enum.routes.root}${Enum.routes.add}`}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    {t('addAuthor', { author: "دانشگاه با اکسل" })}
                  </Button> */}
                  <Button
                    variant="contained"
                    color={'success'}
                    component={RouterLink}
                    to={`${Enum.routes.root}${Enum.routes.add}`}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    {t('addAuthor', { author: Enum.title.name[0] })}
                  </Button>
                </Stack>
              </>
            )
          }
        />
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
          topOfHeaderFilter={
            <>
              <FormProvider methods={methods}>

                <Stack
                  direction={"row"}
                  gap={1}
                sx={{
                  // width: 600
                }}>
                  <ProvinceSelector.Element
                    onChange={(value) => tableSetting.setFilter("iran_province_id", value?.value)}
                    geById={SearchParams.get("iran_province_id") && SearchParams.get("iran_province_id")}
                    name={"iran_province_id"}
                    label={'استان'}
                    sx={{
                      width: 250,
                      '& .MuiInputLabel-root ': {
                        "line-height": "1.0375em"
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      },
                    }}
                    disableClearable={false}
                  />
                  <universityCategorySelector.Element
                    name={"category_id"}
                    geById={SearchParams.get("category_id") && SearchParams.get("category_id")}
                    onChange={(value) => tableSetting.setFilter("category_id", value?.value)}
                    label={'نوع دانشگاه'}
                    sx={{
                      width: 400,
                      '& .MuiInputLabel-root ': {
                        "line-height": "1.0375em"
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      },
                    }}
                    disableClearable={false}
                  />
                </Stack>

              </FormProvider>
            </>
          }
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
