import { Typography, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RHFRadioGroups from 'src/components/hook-form/RHFRadioGroups';

const SelectBed = ({ caravan, onClose, onAccept, data, remainingBed }) => {
  console.log('* * * SelectBed', { caravan });
  const { t } = useTranslation();
  const options = [
    {
      label: `تک خواب (${remainingBed?.single})`,
      value: 1,
      disabled: !remainingBed?.single,
    },
    {
      label: `دو خواب (${remainingBed?.double})`,
      value: 2,
      disabled: !remainingBed?.double,
    },
    {
      label: `سه خواب (${remainingBed?.triple})`,
      value: 3,
      disabled: !remainingBed?.triple,
    },
  ];

  const [state, setState] = useState();

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '550px',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        px: 3,
        py: 2,
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
        }}
      >
        {'تخت خواب'}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          my: 3,
          overflow: 'unset',
        }}
      >
        {
          <DialogContentText>
            {
              <RHFRadioGroups
                label={'یک مورد را انتخاب نمایید'}
                disableForm
                options={options}
                onChange={setState}
                value={state}
              />
            }
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          mt: 3,
          gap: 3,
          justifyContent: 'center',
        }}
      >
        <LoadingButton fullWidth type="click" variant="outlined" color="success" onClick={onClose}>
          {'لغو'}
        </LoadingButton>

        <LoadingButton
          fullWidth
          type="click"
          variant="contained"
          color="success"
          onClick={() => onAccept({ ...data, bed: state?.value })}
          disabled={!state}
        >
          {'تایید'}
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default SelectBed;
