import UniversitySelector from 'src/pages/university/selector';
import universityCategorySelector from 'src/pages/universityCategory/selector';
import { PATH_DASHBOARD } from 'src/routes/paths';
import api from 'src/services/api';

export const optionsSelect = [
  {
    label: 'پیش فرض',
    value: false,
  },
  {
    label: 'نوع دانشگاه',
    value: 'isUniversityCategory',
    isUniversityCategory: true,
    selector: universityCategorySelector,
  },
  {
    label: 'دانشگاه',
    value: 'isUniversity',
    isUniversity: true,
    selector: UniversitySelector,
  },
];

const EPublicForm = {
  title: '',
  bseName: 'a_',
  routes: PATH_DASHBOARD.form,
  api: api.public.form,
};

export default EPublicForm;
