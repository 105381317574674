import * as yup from 'yup';

const validation = {
  fieldNames: {
    status: 'status',
    bankStatus: 'bank_status',
    dutyStatus: 'duty_status',
    identityStatus: 'identity_status',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.status]: yup.mixed().nullable(),
      [validation.fieldNames.bankStatus]: yup.mixed().nullable(),
      [validation.fieldNames.dutyStatus]: yup.mixed().nullable(),
      [validation.fieldNames.identityStatus]: yup.mixed().nullable(),
    }),
};

export default validation;
