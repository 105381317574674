/* eslint-disable */
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../../components/hook-form/FormProvider';
import validation from './validation';

const Reason = ({ onClose, onSubmit: submit, isLoading }) => {
  const { t } = useTranslation();
  const queryParams = useParams();

  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ---------------------------------------

  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      [validation.fieldNames.reason]: values[validation.fieldNames.reason],
    };

    console.log('* * * onSubmit : ', { reqData, values });
    submit(reqData);
  };

  console.log({ queryParams });
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '550px',

        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <FormProvider id={'reasonForm'} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Typography sx={{ textAlign: 'start' }}>{'دلیل رد کردن خود را بنویسید'}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={16}>
              <RHFTextField name={validation.fieldNames.reason} label={''} />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton>

          <LoadingButton
            fullWidth
            form="reasonForm"
            variant="contained"
            color={'success'}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {'ارسال'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default Reason;
