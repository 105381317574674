import { Box, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';

import validation from './validation';
// import RHFSelectStatic from '../../../components/hook-form/RHFSelectStatic';
import RHFSelector from '../../../components/hook-form/RHFSelector';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import { fDate, fDateApi, fDateForApi, fDateJalaliForApi, fDateToGregorian, toPersianDateWithTime } from '../../../utils/formatTime';
import { doneStatusType, statusType } from '../../../enumeration/index';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';
import RemoveNullObjectValue from '../../../utils/RemoveNullObjectValue';
import { useNavigate, useParams } from 'react-router';
import LoadingScreen from 'src/components/LoadingScreen';
import WaitingBox from 'src/components/WaitingBox';
import { PATH_DASHBOARD } from 'src/routes/paths';

const AddCourse = ({ onClose }) => {
  const paramId = undefined;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {id , type} = useParams();


  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${id}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${id}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    reset({
      [validation.fieldNames.type]: null,
      [validation.fieldNames.name]: '',
    });
    if(id){
      // navigate(PATH_DASHBOARD.visitorManagement.root(type , id) + "/registrations")
      dataById.refetch();
    }else{
      onClose();
    }
  };
  const onErrorMutating = (error) => {
    const errorTitle = error.response.data.message || t('errorTryAgain');
    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: id ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccessDataById = (resData) => {

    const helpData = resData?.data ;

    const resetParam = {
      [validation.fieldNames.startDate]: persianToEnglishNumber(new Date(helpData?.registration_start_at)),
      [validation.fieldNames.endDate]: persianToEnglishNumber(new Date(helpData?.registration_finish_at)),
      [validation.fieldNames.endDate1]: persianToEnglishNumber(new Date(helpData?.registration_finish_at)),
      [validation.fieldNames.type]: Enum.enum.object[helpData?.type],
      [validation.fieldNames.name]: helpData?.name,
      [validation.fieldNames.team_count]: helpData?.team_count,
      [validation.fieldNames.is_active]: statusType[helpData?.is_active],
      [validation.fieldNames.is_lottery_done]: doneStatusType[helpData?.is_lottery_done],
    };

    console.log({ resData , resetParam });
    reset(resetParam);
  };
  const dataById = useQueryCustom({
    name: `getById_${Enum?.api?.base}_${id}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!id,
  });

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();

    const reqData = {
      [validation.fieldNames.startDate]: persianToEnglishNumber(fDateForApi(values[validation.fieldNames.startDate])),
      [validation.fieldNames.endDate]: persianToEnglishNumber(fDateForApi(values[validation.fieldNames.endDate])),
      [validation.fieldNames.type]: values[validation.fieldNames.type]?.value,
      [validation.fieldNames.name]: values[validation.fieldNames.name],
      [validation.fieldNames.team_count]: +values[validation.fieldNames.team_count],
      [validation.fieldNames.is_active]: values[validation.fieldNames.is_active]?.value,
    };

    if(id){
      reqData[validation.fieldNames.is_lottery_done] = values[validation.fieldNames.is_lottery_done]?.value;
      delete reqData[validation.fieldNames.type];
      delete reqData[validation.fieldNames.team_count];
    }

    console.log({ reqData , values });
    // return;
    mutate(RemoveNullObjectValue(reqData));
  };

  return (
    <>
      {dataById?.isLoading ? <WaitingBox /> :
        <>
          <Box
            sx={{
              maxHeight:id ? 'auto' : '90vh',
              overflow: 'auto',
              gap: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              p: 4,
              m: 4,
            }}
          >
            <Box
              sx={{
                textAlign:id ? 'left' : 'center',
                mb: '30px',
              }}
            >
              <Typography variant="h5">
                {id?
                `شما در حال ویرایش سفر ${dataById?.data?.data?.name} هستید`
                :'شما در حال ایجاد فرم جدید برای ثبت نام هستید'
                }
              </Typography>
            </Box>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                {!id && 
                  <RHFSelector options={Enum.enum.array} name={validation.fieldNames.type} label={'نوع دوره را انتخاب کنید.'} absolute={!!id}/>
                }

                {id ?
                <RHFTextField name={validation.fieldNames.name} label={'نام دوره'}
                InputLabelProps={{
                  shrink: true, // This forces the label to always stay above the text field
                }}
                />
                :
                <RHFTextField name={validation.fieldNames.name} label={'نام دوره'} />
                
                }
                {!id && 
                  <RHFTextField name={validation.fieldNames.team_count} label={'تعداد اولیه کاروان ها'} type={'number'} />
                }
                <RHFDatePicker name={validation.fieldNames.startDate} label={'تاریخ و زمان شروع ثبت نام'} />
                <RHFDatePicker
                  pickerProps={{ minDate: watchStartDate }}
                  name={validation.fieldNames.endDate}
                  label={'تاریخ و زمان پایان ثبت نام'}
                />
                <RHFSelector options={Object.values(statusType)} name={validation.fieldNames.is_active} label={'وضعیت'} />
                {id &&
                  <RHFSelector options={Object.values(doneStatusType)} name={validation.fieldNames.is_lottery_done} label={'وضعیت قرعه کشی'} />
                }
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent:"flex-end",
                  mt: 3,
                  gap: 3,
                }}
              >
                {!id &&
                  <>
                    <LoadingButton
                      fullWidth
                      // type="click"
                      variant="outlined"
                      color="success"
                      loading={isLoading}
                      onClick={onClose}
                    >
                      {'لغو'}
                    </LoadingButton>
                  </>
                }

                <LoadingButton fullWidth={!id} type="submit" variant="contained" color={'success'} loading={isLoading}>
                  {'تایید'}
                </LoadingButton>
              </Box>
            </FormProvider>
          </Box>
        </>
      }

    </>
  );
};

export default AddCourse;
