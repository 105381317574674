const api = {
  auth: {
    login: '/admin/login',
  },
  omre: {
    base: '/',
  },
  lottery: {
    base: '/admin/team/lottery',
    test: '/lottery',
    main: '/admin/lottery',
  },
  message: {
    base: '/admin/team/message',
  },
  notification: {
    base: '/admin/notification',
  },

  caravan: {
    base: '/admin/team',
    agent: '/admin/team/agent',
    client: '/admin/team/client',
    social: '/admin/team/social',
    message: '/admin/team/message',
    bank: '/admin/team/bank',
    input: '/admin/team/input',
  },
  admin: {
    base: '/admin',
  },
  role: {
    base: '/admin/role',
  },
  permission: {
    base: '/admin/permission',
  },
  agent: {
    base: '/admin/agent',
  },
  changeStatus: {
    base: '/admin/change_status',
  },
  agentRole: {
    base: '/admin/agent',
  },
  university: {
    base: '/admin/university',
    uploadExcel : '/admin/university_excel'
  },
  universityCategory: {
    base: '/admin/university_category',
  },
  travel: {
    base: '/admin/travel',
  },
  bank: {
    base: '/admin/team/bank',
  },

  travelHistory: { base: '/admin/travel/history' },
  travelRegister: {
    base: '/admin/travel_register',
    register: { base: '/admin/register' },
    excel : (id) =>  `/admin/travel_registers/${id}/export`
  },
  form: {
    base: '/admin/form',
    input: '/admin/form/input',
    sample: '/admin/form/sample',
    sampleForm: '/admin/sample/form',
    sectionInput: '/admin/form/section_input',
  },

  createFormBySample: {
    base: '/admin/create_form_by_sample',
  },
  social: {
    base: '/admin/social',
  },
  section: {
    base: '/admin/form/section',
  },
  // section: {
  //   base: '/admin/form/section',
  // },
  input: {
    base: '/admin/form/input',
  },
  public: {
    form: {
      base: '/public/form',
    },
    section: {
      base: '/public/form/section',
    },
    input: {
      base: '/public/form/input',
    },
    travel: {
      base: '/public/travel',
    },
    travelRegister: {
      base: '/admin/travel_register',
    },
  },
  client: {
    base: '/admin/client',
    register: '/admin/client/register',
  },
  // client: {
  //   travel: {
  //     base: '/client/travel',
  //   },
  //   auth: {
  //     login: '/client/login',
  //     verify: '/client/verify_code',
  //   },
  // },
  province: {
    base: '/public/province',
    byId: '/province',
  },
  city: {
    base: '/public/city',
    byId: '/city',
  },
};

export default api;
