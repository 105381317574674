import { ArrowBack } from '@mui/icons-material';
import { useMemo } from 'react';
import buildSideSubitems from 'src/utils/buildSideSubitems';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

import axiosInstance from '../../../utils/axios';
import EForms from '../../../pages/Form/enum';
import { travelTypeObject } from '../../../enumeration/index';

import permissions, {
  adminPermission,
  newsAgencyPermission,
  rolePermission,
  sellerPermission,
  ticketPermission,
  transactionPermission,
  TravelRegisterTamatoPermission,
} from '../../../permission';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import buildAccess from '../../../permission/buildAccess';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('team'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  tournament: getIcon('tournament'),
  support: getIcon('support'),
  building: getIcon('building'),
};

const useNavConfig = () => {
  const { isAuthenticated } = useAuth();

  const getting = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(EForms.api.base, {
      params: {
        type: params.type,
        is_active: 1,
      },
    });
  };

  const gettingSide = async () => {
    return axiosInstance.get(api.travel.base, {});
  };

  const sideQuery = useQueryCustom({
    name: `${api.travel.base}_get`,
    url: gettingSide,
  });

  const sideTabs = sideQuery?.data?.data;
  // useMemo(() => {
  //   const tab = {
  //     [travelTypeObject.omre.value]: [],
  //     [travelTypeObject.atabat_aliat.value]: [],
  //     [travelTypeObject.other.value]: [],
  //     [travelTypeObject.tamato.value]: [],
  //   };

  //   if (sideQuery?.data?.data?.length) {
  //     for (let i = 0; i < sideQuery?.data?.data?.length; i += 1) {
  //       const curr = sideQuery?.data?.data?.[i];
  //       tab[curr.type] = tab[curr.type] || [];
  //       tab[curr.type] = curr.travels;
  //     }
  //   }
  //   return tab;
  // }, [sideQuery]);

  const buildSidebar = (key) => {
    const array = [];

    const parameter = sideTabs?.[key?.value]?.map((x) => {
      // const forms = x.forms?.map((y) => ({
      //   title: y.name,
      //   path: `${PATH_DASHBOARD.form.root(key?.value, x.id)}/${y.id}`,
      // }));
      // console.log('* * * buildSidebar :', { key, x }, `${PATH_DASHBOARD[key.route]?.root}/${x.id}`);
      return {
        title: x.name,
        path: `${PATH_DASHBOARD[key.route]?.root}/${x.id}`,
        children: buildSideSubitems(key, x.id),
        type: key?.type,
      };
    });

    if (sideTabs?.[key?.value])
      array.push(...parameter, {
        title: 'سوابق',
        path: PATH_DASHBOARD.records.root(key?.value),
        type: key?.type,
        // permission: buildAccess(PATH_DASHBOARD.records.name, travelTypeObject[key]?.type)?.read,
      });
    return array;
  };

  console.log({ sideTabs, sideQuery });

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'داشبورد',
    //   items: [
    //     { title: 'اعلانات', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    // { title: 'اخبار', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
    // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    //   ],
    // },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      // subheader: '',
      items: [
        {
          title: 'عناوین',
          path: PATH_DASHBOARD.general.app,
          // icon: ICONS.dashboard,
          // isCollapse: true,
        },
        {
          title: 'حج عمره',
          path: PATH_DASHBOARD.omre.root,
          // permission: permissions.travelRegister.access(travelTypeObject.omre.type),
          type: travelTypeObject.omre.type,
          children: buildSidebar(travelTypeObject.omre),
        },
        {
          title: 'حج تمتع',
          path: PATH_DASHBOARD.tamato.root,
          // icon: ICONS.analytics,
          // permission: permissions.travelRegister.access.read,
          type: travelTypeObject.tamato.type,
          children: buildSidebar(travelTypeObject.tamato),
        },
        {
          title: 'عتبات عالیات',
          path: PATH_DASHBOARD.atabatAliat.root,
          type: travelTypeObject.atabat_aliat.type,
          // permission: permissions.at.access.read,
          children: buildSidebar(travelTypeObject.atabat_aliat),
        },
        {
          title: 'سفرهای زیارتی دیگر',
          path: PATH_DASHBOARD.team.list,
          type: travelTypeObject.other.type,
          // icon: ICONS.team,
          children: buildSidebar(travelTypeObject.other),
        },
        {
          title: 'عوامل',
          path: PATH_DASHBOARD.agentSections.root,
          // icon: ICONS.tournament,
          permission: Object.values(buildAccess(PATH_DASHBOARD.admin.name) || {}),

          children: [
            {
              title: 'لیست عوامل',
              path: PATH_DASHBOARD.agent.root,
              permission: Object.values(buildAccess(PATH_DASHBOARD.admin.name) || {}),
            },
            {
              title: 'نقش ها',
              path: PATH_DASHBOARD.agentRole.root,
              permission: Object.values(buildAccess(PATH_DASHBOARD.admin.name) || {}),
            },
          ],
        },
        // {
        //   title: 'سایر ثبت نام ها',
        //   path: PATH_DASHBOARD.tournament.list,
        //   // icon: ICONS.tournament,
        // },
        {
          title: 'دانشگاه ها',
          path: PATH_DASHBOARD.universitiesSection.root,
          // icon: ICONS.support,
          permission: Object.values(buildAccess(PATH_DASHBOARD.university.name) || {}),

          children: [
            {
              title: 'لیست دانشگاه',
              path: PATH_DASHBOARD.university.root,
              permission: Object.values(buildAccess(PATH_DASHBOARD.university.name) || {}),
            },
            {
              title: 'نوع دانشگاه',
              path: PATH_DASHBOARD.universityCategory.root,
              permission: Object.values(buildAccess(PATH_DASHBOARD.university.name) || {}),
            },
          ],
        },
        {
          title: 'پروفایل ها',
          path: PATH_DASHBOARD.profile.root,
          permission: Object.values(buildAccess(PATH_DASHBOARD.profile.name) || {}),
          // icon: ICONS.building,
        },
        {
          title: 'پیامک ها',
          path: PATH_DASHBOARD.messages.root,
          permission: Object.values(buildAccess(PATH_DASHBOARD.messages.name) || {}),
        },
        {
          title: 'پیام ها',
          path: PATH_DASHBOARD.notifications.root,
          permission: Object.values(buildAccess(PATH_DASHBOARD.messages.name) || {}),
          // permission: Object.values(buildAccess(PATH_DASHBOARD.notifications.name) || {}),
        },
        // {
        //   title: 'Users',
        //   path: PATH_DASHBOARD.user.list,
        //   icon: ICONS.user,
        //   // permission:rolePermission.read
        //   // children: [
        //   //   { title: 'نقش های تخفیفی', path: PATH_DASHBOARD.clientType.list },
        //   //   { title: 'کشور', path: PATH_DASHBOARD.country.list },
        //   //   { title: 'زبان', path: PATH_DASHBOARD.language.list },
        //   // ],
        // },
        // {
        //   title: 'Managers',
        //   path: PATH_DASHBOARD.admin.list,
        //   icon: ICONS.user,
        // },

        // STORE
        // {
        //   title: ' محتوای ویژه',
        //   path: PATH_DASHBOARD.specialContent.root,
        //   icon: ICONS.invoice,
        //   children: [
        //     { title: 'لیست', path: PATH_DASHBOARD.specialContent.list },
        //     // { title: 'post', path: PATH_DASHBOARD.blog.demoView },
        //     { title: 'ایجاد', path: PATH_DASHBOARD.specialContent.new },
        //     { title: 'ایجاد لیست محتوای ویژه', path: PATH_DASHBOARD.specialContent.new_playlist },
        //   ],
        // },
      ],
    },

    // Panel
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'مدیریت پنل',
    //   items: [
    //     // Admin
    //     {
    //       title: 'مدیریت ادمین ها',
    //       path: PATH_DASHBOARD.admin.list,
    //       icon: ICONS.user,
    //       children: [
    //         // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
    //         // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
    //       ],
    //     },
    //   ],
    // },

    // APP
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'app',
    //   items: [
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: (
    //         <Label variant="outlined" color="error">
    //           +32
    //         </Label>
    //       ),
    //     },
    //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
    //   ],
    // },
  ];
};

export default useNavConfig;
