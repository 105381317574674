import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const obj = {
  0: {
    label: '',
    value: 0,
    color: 'error',
  },
  1: {
    label: '',
    value: 1,
    color: 'success',
  },
  2: {
    label: '',
    value: 2,
    color: 'warning',
  },
  3: {
    label: '',
    value: 3,
    color: 'grey',
  },
};

const EVisitorManagement = {
  title: { name: ['زائرین', 'زائرین'] },
  bseName: 'a_',
  routes: PATH_DASHBOARD.visitorManagement,
  api: api.travelRegister,
  lotteryApi : {
    form : api.form,
    lottery : api.lottery,
    travel : api.travel
  },
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
  status: {
    array: Object.values(obj),
    object: obj,
  },
  // permission: {
  //   read: 'TravelRegister read',
  //   update: 'TravelRegister update',
  //   create: 'TravelRegister create',
  //   delete: 'TravelRegister delete',
  //   confirm: 'TravelRegister confirm',
  // },
};

export default EVisitorManagement;
