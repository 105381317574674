/* eslint-disable no-lonely-if */
import { Grid, InputAdornment } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFDatePicker from '../../../../components/hook-form/RHFDatePicker';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputDate = ({ name, data, disabled, index, refetch, withGridBox, gridSize, ...props }) => {
  const [hidden, setHidden] = useState(false);

  const { control, watch, setValue } = useFormContext();

  let watchParent;
  let watchParent1;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);
  if (data?.options?.parentId1) watchParent1 = watch(`${data?.options?.parentId1}`);

  // console.log("* * * FormInputLocationCity : ",{name,data,watchProvince},`${data?.parentProvinceName}`);

  const options = useMemo(() => {
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
      selector: data?.options?.selectType?.selector,
    };
  }, [data]);

  useEffect(() => {
    if (!watchParent) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on || watchParent1?.value === afterChangeParent.on;
      if (afterChangeParent.by === 'child') {
        if (is) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        }else if (afterChangeParent.showOn) {

          if(Array.isArray(afterChangeParent.on)){
            const indexSelectedItem = afterChangeParent.on?.indexOf(watchParent?.value) === -1 ? afterChangeParent.on?.indexOf(watchParent1?.value) : afterChangeParent.on?.indexOf(watchParent?.value);

            if (
              indexSelectedItem > -1 
            ){
               
              const hiddenItemIndex = afterChangeParent?.hiddenItem[indexSelectedItem]?.[data?.id]
              if(hiddenItemIndex) {
                setHidden(false);
              }
              else { setHidden(true); setValue(name, null) }
            }
            else { setHidden(true); setValue(name, null) }
          }else{
            if (is) setHidden(false)
            else { setHidden(true); setValue(name, null) }
          }


        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
      }
      if (afterChangeParent?.hiddenOn) {
        // console.log(data?.label, afterChangeParent?.hiddenItem)
        for (const key in afterChangeParent?.hiddenItem) {
          if (Object.prototype.hasOwnProperty.call(afterChangeParent?.hiddenItem, key)) {
            const element = afterChangeParent?.hiddenItem[key];
            // console.log({
            //   hiddenItem: element,
            //   item: data,
            //   label: data?.label,
            //   sdas: watchParent?.value,
            //   asdas: afterChangeParent?.hiddenOn,
            //   con: (element?.input?.label?.trim() === data?.label || key === data?.id?.toString()) && (watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn)
            // })
            if ((element?.input?.label?.trim() === data?.label || key === data?.id?.toString()) && (watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn)) {
              setHidden(true)
              setValue(name, null);
            } else if ((watchParent?.value !== afterChangeParent?.hiddenOn && watchParent1?.value !== afterChangeParent?.hiddenOn) && hidden) {
              setHidden(false)
            }
          }
        }
      }
    }
  }, [watchParent , watchParent1]);

  console.log({ options, watchParent, data }, data?.label, '* * * FormInputSelect');
  if (hidden) return <></>;

  return (
    withGridBox ?
      <Grid item {...gridSize}>

        <RHFDatePicker
          name={name}
          label={data?.label}
          disabled={disabled}
          required={data?.options?.required}
          inputProps={{
            autoComplete: 'off',
            ...(data?.need_confirm
              ? //  && isHistory
              {
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <AcceptOrRejectWithQuestionsWithReason
                      {...data?.client_inputs?.[index || 0]}
                      history={data?.old_client_inputs?.[index || 0]}
                      type={data?.type}
                      refetch={refetch}
                    />
                  </InputAdornment>
                ),
              }
              : {}),
          }}
          pickerProps={data?.options?.inputProps}
          {...props}
        />
      </Grid>
      :
      <RHFDatePicker
        name={name}
        label={data?.label}
        disabled={disabled}
        required={data?.options?.required}
        inputProps={{
          autoComplete: 'off',
          ...(data?.need_confirm
            ? //  && isHistory
            {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason
                    {...data?.client_inputs?.[index || 0]}
                    history={data?.old_client_inputs?.[index || 0]}
                    type={data?.type}
                    refetch={refetch}
                  />
                </InputAdornment>
              ),
            }
            : {}),
        }}
        pickerProps={data?.options?.inputProps}
        {...props}
      />
  );
};

export default FormInputDate;
