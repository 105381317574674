/* eslint-disable no-lonely-if */
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import ProvinceSelector from '../../../../../components/selector/Province';
import AcceptOrRejectWithQuestionsWithReason from '../../AcceptOrRejectWithQuestionsWithReason';

const FormInputLocationProvince = ({ name, data, isHistory, index, refetch, withGridBox, gridSize, ...props }) => {
  const { setValue, watch, getValues } = useFormContext();

  const [hidden, setHidden] = useState(false);
  const [FilterOptions, setFilterOptions] = useState([])


  const childCityName = index >= 0 ? data?.childCityNames?.[index] : data?.childCityName;

  let watchParent;
  let watchParent1;
  let watchParent2;
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);
  if (data?.options?.parentId1) watchParent1 = watch(`${data?.options?.parentId1}`);
  if (data?.options?.afterChangeParent?.checkParentInOtherSection) watchParent2 = watch(`${data?.options?.afterChangeParent.checkParentInOtherSectionData?.options?.parentId}`);


  const handleChange = (a, v) => {
    // console.log('* * * FormInputLocationProvince handleChange : ', { a, v });
    if (childCityName) setValue(childCityName, undefined);
  };

  useEffect(() => {
    if (!watchParent && !watchParent1) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on || watchParent1?.value === afterChangeParent.on;
      // const showOn = watchParent?.value === afterChangeParent.showOn;
      if (afterChangeParent.by === 'child') {
        if (is && !afterChangeParent.showOn) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        } else if (afterChangeParent.showOn) {

          if(Array.isArray(afterChangeParent.on)){
            const indexSelectedItem = afterChangeParent.on?.indexOf(watchParent?.value) === -1 ? afterChangeParent.on?.indexOf(watchParent1?.value) : afterChangeParent.on?.indexOf(watchParent?.value);

            if (
              indexSelectedItem > -1 
            ){

              
              const hiddenItemIndex = afterChangeParent?.hiddenItem?.[indexSelectedItem]?.[data?.id]
              if(hiddenItemIndex) {
                setHidden(false);
              }
              else { setHidden(true); setValue(name, null) }
            }
            else { setHidden(true); setValue(name, null) }
          }else{
            if (is) setHidden(false)
            else { setHidden(true); setValue(name, null) }
          }

          // if (is) setHidden(false)
          // else { setHidden(true); setValue(name, null) }

        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
        // else if (afterChangeParent.type === 'resetOptions') {
        if (afterChangeParent.type === 'resetOptions' || afterChangeParent.opType === 'resetOptions') {
          let helpArr = [...FilterOptions];

          let findIndex = afterChangeParent.on?.indexOf(watchParent?.value)

          if (findIndex === -1) {
            findIndex = afterChangeParent.on?.indexOf(watchParent1?.value)
          }

          if (findIndex > -1) {
            if (helpArr[afterChangeParent?.filterIndex]) {
              helpArr[afterChangeParent?.filterIndex] = afterChangeParent?.removeWord[findIndex];
            } else {
              if (afterChangeParent?.filterIndex === 0) {
                helpArr.push(afterChangeParent?.removeWord[findIndex]);
              } else {
                helpArr.length = Math.max(helpArr.length, afterChangeParent?.filterIndex + 1);

                helpArr.fill(null, helpArr.length, afterChangeParent?.filterIndex);

                helpArr[afterChangeParent?.filterIndex] = afterChangeParent?.removeWord[findIndex];
              }
            }

            const optionsArr = data?.options?.items
              ?.filter((x) =>
                !helpArr.some((word) => {
                  if (Array.isArray(word)) {
                    return word.some(subWord => x?.label?.includes(subWord));
                  }
                  return x?.label?.includes(word);
                })
              )
              ?.map((x) => ({ label: x.label, value: x.label }))
            const findSelectedItemOpIndex = optionsArr?.findIndex(item => item?.value === getValues(name)?.value)
            if (findSelectedItemOpIndex === -1) setValue(name, null);
          } else {
            helpArr.length = 0;
          }

          if (afterChangeParent?.otherParentControll) {
            if (
              watchParent?.value === afterChangeParent?.otherParentControll ||
              watchParent1?.value === afterChangeParent?.otherParentControll ||
              (afterChangeParent?.otherParentControll || [])?.indexOf(watchParent?.value) > -1 ||
              (afterChangeParent?.otherParentControll || [])?.indexOf(watchParent1?.value) > -1
            ) {
              if (Array.isArray(afterChangeParent?.otherParentControll)) {
                let findIndex1 = afterChangeParent.otherParentControll?.indexOf(watchParent?.value)

                if (findIndex1 === -1) {
                  findIndex1 = afterChangeParent.otherParentControll?.indexOf(watchParent1?.value)
                }
                if (findIndex1 > -1) {
                  if (afterChangeParent?.otherParentFilterIndex) {

                    helpArr[afterChangeParent?.otherParentFilterIndex] = afterChangeParent?.otherParentControllFilter[findIndex1];
                  } else {
                    helpArr = afterChangeParent?.otherParentControllFilter[findIndex1];
                  }
                }
              } else {
                if (afterChangeParent?.otherParentFilterIndex) {
                  helpArr[afterChangeParent?.otherParentFilterIndex] = afterChangeParent?.otherParentControllFilter;
                } else {
                  helpArr = afterChangeParent?.otherParentControllFilter;
                }
              }

              const optionsArr = data?.options?.items
                ?.filter((x) =>
                  !helpArr.some((word) => {
                    if (Array.isArray(word)) {
                      return word.some(subWord => x?.label?.includes(subWord));
                    }
                    return x?.label?.includes(word);
                  })
                )
                ?.map((x) => ({ label: x.label, value: x.label }))
              const findSelectedItemOpIndex = optionsArr?.findIndex(item => item?.value === getValues(name)?.value)
              if (findSelectedItemOpIndex === -1) setValue(name, null);
              // setValue(name, null);
            }
          }


          setFilterOptions(helpArr)
        }
        if (afterChangeParent?.checkParentInOtherSection) {
          // console.log(data?.label, afterChangeParent?.hiddenItem)
          for (const key in afterChangeParent?.hiddenItem) {
            if (Object.prototype.hasOwnProperty.call(afterChangeParent?.hiddenItem, key)) {
              const element = afterChangeParent?.hiddenItem[key];
              // console.log({
              //   hiddenItem: element,
              //   item: data,
              //   label: data?.label,
              //   sdas: watchParent?.value,
              //   sdas1: watchParent1?.value,
              //   asdas: afterChangeParent?.hiddenOn,
              //   checkParentInOtherSection: afterChangeParent?.checkParentInOtherSectionData,
              //   con: (element?.input?.label?.trim() === data?.label) && (watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn)
              // })
              if (
                (element?.input?.label?.trim() === data?.label) &&
                ((watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn))
              ) {

                const { checkParentInOtherSectionData } = afterChangeParent;
                // console.log({ checkParentInOtherSectionData: checkParentInOtherSectionData?.options?.afterChangeParent?.hiddenOn, watchParent2 })

                if (watchParent2?.value === checkParentInOtherSectionData?.options?.afterChangeParent?.hiddenOn) {
                  setHidden(true)
                  setValue(name, null);
                } else if ((
                  // watchParent?.value !== afterChangeParent?.hiddenOn && 
                  watchParent1?.value !== afterChangeParent?.hiddenOn &&
                  watchParent2?.value !== checkParentInOtherSectionData?.options?.afterChangeParent?.hiddenOn) && hidden) {
                  setHidden(false)
                }
              } else if ((watchParent?.value !== afterChangeParent?.hiddenOn && watchParent1?.value !== afterChangeParent?.hiddenOn) && hidden) {
                setHidden(false)
              }

            }
          }
        }
        else if (afterChangeParent?.hiddenOn) {
          // console.log(data?.label, afterChangeParent?.hiddenItem)
          for (const key in afterChangeParent?.hiddenItem) {
            if (Object.prototype.hasOwnProperty.call(afterChangeParent?.hiddenItem, key)) {
              const element = afterChangeParent?.hiddenItem[key];
              // console.log({
              //   hiddenItem: element,
              //   item: data,
              //   label: data?.label,
              //   sdas: watchParent?.value,
              //   sdas1: watchParent1?.value,
              //   asdas: afterChangeParent?.hiddenOn,
              //   con: (element?.input?.label?.trim() === data?.label) && (watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn)
              // })
              if (
                (element?.input?.label?.trim() === data?.label) &&
                ((watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn))
              ) {
                setHidden(true)
                setValue(name, null);
              }
              else if ((watchParent?.value !== afterChangeParent?.hiddenOn && watchParent1?.value !== afterChangeParent?.hiddenOn) && hidden) {
                setHidden(false)
              }
            }
          }
        }
      }
    }
  }, [watchParent, watchParent1, watchParent2]);

  console.log('* * * FormInputLocationProvince : ', { childCityName, index, data });
  if (hidden) return <></>;
  return (

    withGridBox ?
      <Grid item {...gridSize}>
        <ProvinceSelector.Element
          geById={data?.client_inputs?.[index || 0]?.value}
          required={data?.options?.required}
          name={name}
          label={data?.label}
          onChange={handleChange}
          InputProps={
            data?.need_confirm
              ? // && isHistory
              {
                endAdornment: (
                  <InputAdornment position="start">
                    <AcceptOrRejectWithQuestionsWithReason
                      {...data?.client_inputs?.[index || 0]}
                      history={data?.old_client_inputs?.[index || 0]}
                      type={data?.type}
                      refetch={refetch}
                    />
                  </InputAdornment>
                ),
              }
              : ''
          }
          {...props}
        />
      </Grid>
      :
      <Box>
        <ProvinceSelector.Element
          geById={data?.client_inputs?.[index || 0]?.value}
          required={data?.options?.required}
          name={name}
          label={data?.label}
          onChange={handleChange}
          InputProps={
            data?.need_confirm
              ? // && isHistory
              {
                endAdornment: (
                  <InputAdornment position="start">
                    <AcceptOrRejectWithQuestionsWithReason
                      {...data?.client_inputs?.[index || 0]}
                      history={data?.old_client_inputs?.[index || 0]}
                      type={data?.type}
                      refetch={refetch}
                    />
                  </InputAdornment>
                ),
              }
              : ''
          }
          {...props}
        />
      </Box>
  );
};

export default FormInputLocationProvince;
