/* eslint-disable no-lonely-if */
import { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress, Grid, FormHelperText } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { CheckCircle, ControlPoint } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { isArray, isNaN } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import axiosInstance from '../../../../utils/axios';
import Enum from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../hooks/useSettings';
import WaitingBox from '../../../../components/WaitingBox/index';
// import SectionPublic from '../../Section';
import FormProvider from '../../../../components/hook-form/FormProvider';
import { formTypesObject, travelTypeObject } from '../../../../enumeration';
import errorsText from '../../../../utils/errorsText';
import validation from './validation';
import api from '../../../../services/api';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import persianToEnglishNumber from '../../../../utils/persianToEnglishNumber';
import { fDateForApi } from '../../../../utils/formatTime';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
// import CaravanInput from '../../Section/InputsCore/Caravan';
import CaravanSelector from 'src/pages/caravansManagement/selector';
import { newDate } from '../../../../utils/dating';
import { optionsSelect } from '../enum';
import SectionPublic from './Section';
import { RHFTextField, RHFUploadSingleFile } from 'src/components/hook-form';
import { capitalizeText, persianToLatinNumbers } from 'src/utils';
import LotteryModalComponent from '../../LotteryModalComponent';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import UniversitySelector from 'src/pages/university/selector';
import ProvinceSelector from 'src/components/selector/Province';
import { DUTY_DEFAULT_OP, GENDER_DEFAULT_OP, PASSPORT_DEFAULT_OP, TASHAROF_DEFAULT_OP } from 'src/config';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import RHFRadioGroups from 'src/components/hook-form/RHFRadioGroups';
import universityCategorySelector from 'src/pages/universityCategory/selector';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';
import axiosInstanceV2 from 'src/utils/axiosV2';

const RegistrantFormLottery = ({ data, refetch, isHistory, travelId, clientId, handleSuccess }) => {
    const ModalLayoutQuestionInputRef = useRef();
    const ModalLayoutSuccessRef = useRef();


    const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
    const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params);

    const queryParams = useParams();
    const paramsTravelId = queryParams?.travel;
    const paramsType = queryParams?.type;

    const { themeStretch } = useSettings();
    const { t } = useTranslation();
    const [validationState, setValidationState] = useState({});
    const [TravelApi, setTravelApi] = useState();
    const [inputsById, setInputsById] = useState();
    const [beforeRegistrant, setBeforeRegistrant] = useState();
    const [successBeforeRegistrant, setSuccessBeforeRegistrant] = useState();
    const [caravanOptions, setCaravanOptions] = useState();
    const [SuccessLotteryApi, setSuccessLotteryApi] = useState({})

    const [companionSectionId, setCompanionSectionId] = useState();
    // const ModalSectionFormRef = useRef();
    const isAttabat = [travelTypeObject.atabat_aliat.value, travelTypeObject.other.value].includes(
        // queryParams.type
        data?.form?.travel?.type
    );

    const isFirst = data.form.order == 0;
    const members = !isFirst && data.members;

    console.log({ isAttabat, caravanOptions, inputsById });
    const navigate = useNavigate();

    const defaultValues = useMemo(
        () => ({
            maxPersions:"",
            name:"",
            last_name:"",
            shenasname:"",
            codeMeli:"",
            father_name:"",
            fromBirthDate: "",
            toBirthDate: "",
            gender: "",
            email: "",
            phone: "",
            marride_status: null,
            tashrof_be_sorat: null,
            gozarnameh_status: null,
            duty_system: null,
            mazhab: null,
            need_vam: null,
            sabegh_tasharof: null,
            home_phone: "",
            has_hamrah: null,
            hamrah_count: null,
            marrid_date_from:"",
            marrid_date_to: "",
            student_marriage: null,
            provoince_university: null,
            type_university: null,
            university_code: null,
            kunkor: null,
            degree: null,
            type_of_employment: null,
            type_of_employment_state: null,

        }),

        []
    );

    const methods = useForm({
        resolver: yupResolver(validation.schema(validationState)),
        // mode: 'onChange',
        mode: 'all',
        shouldUnregister: false,
        defaultValues:defaultValues
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = methods;

    const isError = Object.keys(errors)?.length;

    const values = getValues();
    console.log({ errors, values });
    // ------------------------------------------------------------------------------ modal ref

    // ------------------------------------------------------------------------------ modal toggle

    // ------------------------------------------------------------------------------ Mutation service
    // ----------------------------------------------------------------------------- SERVICE
    const creating = (params) => axiosInstanceV2.post(api.lottery.main, params);
    const updating = (params) =>
        axiosInstance.post(`${api.travelRegister.base}/${travelId || data?.form?.travel?.id}`, params);
    // const getById = async ({ queryKey }) => {
    //   const [_, params] = queryKey || [];
    //   return axiosInstance.get(`${Enum?.api?.base}/${id}`);
    // };
    // const getById = () => axiosInstance.get(`${api.input.base}/${data}`);
    // ------------------------------------------------------------------------------ Mutation
    const onSuccessMutating = (data) => {
        handleSuccessModal();
        refetch?.();
        handleSuccess?.(data);
        console.log({ data })
        setSuccessLotteryApi(data?.data)
    };

    const onErrorMutating = (error) => {
        console.log({ error });
        const errorTitle = error.response.data.message || t('errorTryAgain');

        const errors = Object.values(error?.response?.data?.errors || {});
        if (errors?.length) {
            errors?.map((x) => {
                return toast.error(x?.[0]);
            });
        } else toast.error(errorTitle);
    };

    const { isLoading, mutate } = useMutationCustom({
        url: beforeRegistrant ? updating : creating,
        name: `${api.public.travelRegister.base}_add`,
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        onSuccess: onSuccessMutating,
        // onError: onErrorMutating,
    });
    // -------
    // ------------------------------------------------------------------------------ handler

    function removeUndefinedValues(formData) {
        const newFormData = new FormData();

        for (const [key, value] of formData.entries()) {
            if (
                value !== undefined && value !== "undefined" && typeof value !== "undefined"
            ) {
                newFormData.append(key, value);
            }
        }

        return newFormData;
    }

    const onSubmit = async () => {
        const formValue = getValues();

        handleQuestionModal();
        const toDate = new Date();

        let reqData = {
            travel_id: travelId || data?.form?.travel?.id,
            maxCapacity : formValue?.maxPersions,
            main_client_name: formValue.name?.trim() !== "" ? formValue.name : undefined,
            main_client_last_name: formValue.last_name?.trim() !== "" ? formValue.last_name : undefined,
            main_client_code_melli: formValue.codeMeli?.trim() !== "" ? persianToEnglishNumber(formValue.codeMeli) : undefined,
            main_client_phone: formValue.phone?.trim() !== "" ? persianToEnglishNumber(formValue.phone) : undefined,
            main_client_shenasname_number: formValue.shenasname?.trim() !== "" ? persianToEnglishNumber(formValue.shenasname) : undefined,
            main_client_father_name: formValue.father_name?.trim() !== "" ? formValue.father_name : undefined,
            main_client_birthdate_from: formValue.fromBirthDate ? persianToEnglishNumber(fDateForApi(formValue.fromBirthDate)) : (formValue.toBirthDate) ? persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))) : undefined,
            main_client_birthdate_to: formValue.toBirthDate ? persianToEnglishNumber(fDateForApi(formValue.toBirthDate)) : (formValue.fromBirthDate) ? persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))) : undefined,
            main_client_gender: formValue.gender?.value ? formValue.gender?.value : undefined,
            main_client_email: formValue.email?.trim() !== "" ? formValue.email : undefined,
            main_client_marital_status: formValue.marride_status?.value ? formValue.marride_status?.value : undefined,
            main_client_tasharof_be_soorat: formValue.tashrof_be_sorat?.value ? formValue.tashrof_be_sorat?.value : undefined,
            main_client_religion: formValue.mazhab?.value ? formValue.mazhab?.value : undefined,
            main_client_home_phone: formValue.home_phone?.trim() !== "" ? formValue.home_phone : undefined,
            main_client_passport_status: formValue.gozarnameh_status?.value ? formValue.gozarnameh_status?.value : undefined,
            main_client_nezam_vazife_status: formValue.duty_system?.value ? formValue.duty_system?.value : undefined,
            main_client_need_vam: formValue.need_vam?.value ? formValue.need_vam?.value : undefined,
            main_client_sabeghe_tasharof: formValue.sabegh_tasharof?.value ? formValue.sabegh_tasharof?.value : undefined,
            main_client_marriage_date_from: formValue.marrid_date ? persianToEnglishNumber(fDateForApi(formValue.marrid_date_from)) : (formValue.marrid_date_to) ? persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))) : undefined,
            main_client_marriage_date_to: formValue.marrid_date ? persianToEnglishNumber(fDateForApi(formValue.marrid_date_to)) : (formValue.marrid_date_from) ? persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))) : undefined,
            main_client_student_marriage: formValue.student_marriage?.value ? formValue.student_marriage?.value : undefined,
            main_client_has_hamrah: formValue.has_hamrah?.value ? formValue.has_hamrah?.value : undefined,
            main_client_hamrah_count: formValue.hamrah_count?.value ? persianToEnglishNumber(formValue.hamrah_count?.value) : undefined,
            main_client_university_city: formValue.provoince_university?.label ? formValue.provoince_university?.label : undefined,
            main_client_university_category: formValue.type_university?.label ? formValue.type_university?.label : undefined,
            main_client_university_name: formValue.university_code?.label ? formValue.university_code?.label : undefined,
            main_client_konkoor: formValue.kunkor?.value ? formValue.kunkor?.value : undefined,
            main_client_education_level: formValue.degree?.value ? formValue.degree?.value : undefined,
            // main_client_is_student: (formValue.tashrof_be_sorat?.value === "استاد دانشگاه" || formValue.tashrof_be_sorat?.value === "کارمند") ? "خیر" : "بله",
            main_client_hokm_kargozini: formValue?.type_of_employment?.value ? formValue.type_of_employment?.value : undefined,
            main_client_university_level: formValue?.type_of_employment_state?.value ? formValue.type_of_employment_state?.value : undefined,

            // hamsar
            hamsar_name: formValue.hamsar_name?.trim() !== "" ? formValue.hamsar_name : undefined,
            hamsar_last_name: formValue.hamsar_last_name?.trim() !== "" ? formValue.hamsar_last_name : undefined,
            hamsar_code_melli: formValue.hamsar_code_melli?.trim() !== "" ? persianToEnglishNumber(formValue.hamsar_code_melli) : undefined,
            hamsar_phone: formValue.hamsar_phone?.trim() !== "" ? persianToEnglishNumber(formValue.hamsar_phone) : undefined,
            hamsar_father_name: formValue.hamsar_father_name?.trim() !== "" ? formValue.hamsar_father_name : undefined,
            // hamsar_birthdate_from: formValue.fromBirthDate ? {value : persianToEnglishNumber(fDateForApi(formValue.fromBirthDate)) , label :"از تاریخ تولد" , name : "fromBirthDate" , filterName : "hamsar_birthdate_from" , type : "DATE" , afterDelete : !formValue.toBirthDate , afterDeleteKey:"hamsar_birthdate_to" } : (formValue.toBirthDate) ? {value : persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))) , label :"تا تاریخ تولد" , name : "toBirthDate" , filterName : "hamsar_birthdate_to" , type : "DATE" , hidden : true} : undefined,
            // hamsar_birthdate_to: formValue.toBirthDate ? {value : persianToEnglishNumber(fDateForApi(formValue.toBirthDate)) , label :"تا تاریخ تولد" , name : "toBirthDate" , filterName : "hamsar_birthdate_to" , type : "DATE" , afterDelete : !formValue.fromBirthDate , afterDeleteKey:"hamsar_birthdate_from"} :(formValue.fromBirthDate) ? {value : persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))) , label :"از تاریخ تولد" , name : "fromBirthDate" , filterName : "hamsar_birthdate_from" , type : "DATE" , hidden : true} : undefined,
            hamsar_gender: formValue.hamsar_gender?.value ? formValue.hamsar_gender?.value : undefined,
            hamsar_passport_status: formValue.hamsar_passport_status?.value ? formValue.hamsar_passport_status?.value : undefined,
            hamsar_nezam_vazife_status: formValue.hamsar_nezam_vazife_status?.value ? formValue.hamsar_nezam_vazife_status?.value : undefined,
            hamsar_need_vam: formValue.hamsar_need_vam?.value ? formValue.hamsar_need_vam?.value : undefined,
            hamsar_student_marriage: formValue.student_marriage?.value ? formValue.student_marriage?.value : undefined,
            hamsar_university_city: formValue.hamsar_university_city?.label ? formValue.hamsar_university_city?.label : undefined,
            hamsar_university_category: formValue.hamsar_university_category?.label ? formValue.hamsar_university_category?.label : undefined,
            hamsar_university_name: formValue.hamsar_university_name?.label ? formValue.hamsar_university_name?.label : undefined,
            hamsar_konkoor: formValue.hamsar_konkoor?.value ? formValue.hamsar_konkoor?.value : undefined,
            hamsar_education_level: formValue.hamsar_education_level?.value ? formValue.hamsar_education_level?.value : undefined,
            hamsar_is_student: formValue?.hamsar_is_student?.value ? formValue.hamsar_is_student?.value : undefined,
        }

        reqData = RemoveNullObjectValue(reqData);

        if(
            reqData?.hamsar_name ||
            reqData?.hamsar_last_name ||
            reqData?.hamsar_code_melli ||
            reqData?.hamsar_phone ||
            reqData?.hamsar_father_name ||
            reqData?.hamsar_gender ||
            reqData?.hamsar_passport_status ||
            reqData?.hamsar_nezam_vazife_status ||
            reqData?.hamsar_need_vam ||
            reqData?.hamsar_student_marriage ||
            reqData?.hamsar_university_city ||
            reqData?.hamsar_university_category ||
            reqData?.hamsar_university_name ||
            reqData?.hamsar_konkoor ||
            reqData?.hamsar_education_level ||
            reqData?.hamsar_is_student
        ){
            reqData.hamsar_nesbat_to_head = {
                value : "همسر",
                hidden : true
            };
        }else{
            delete reqData.hamsar_nesbat_to_head
        }

        mutate(reqData);
        // setSuccessBeforeRegistrant(beforeRegistrant);
    };

    // const persianNumber = 'asdad۱۲۳۴۵۶';
    // const englishNumber = persianToEnglishNumber(persianNumber);
    // console.log({englishNumber});
    // const getting = async () =>
    //   axiosInstance.get(api.section.base, {
    //     params: {
    //       form_id: id,
    //     },
    //   });

    const [NewFormData, setNewFormData] = useState({})

    return (
        <Box
            sx={
                {
                    // py:3
                }
            }
        >
            {
                <Page
                    sx={{
                        py: 3,
                        px: 0
                    }}
                    title={data?.form?.name}
                >
                    <ModalLayout ref={ModalLayoutQuestionInputRef}>
                        <QuestionComponent
                            {...{
                                title: 'آیا از انجام قرعه کشی مطمئن هستید؟',
                                description: 'پس از تایید اطلاعات ثبت شده ، قرعه کشی انجام میشود، آیا ادامه میدهید؟',
                                button: {
                                    confirm: {
                                        label: 'question.yesProcess',
                                        onClick: (_, data) => onSubmit(data),
                                    },
                                    reject: {
                                        label: 'question.no',
                                        onClick: () => handleQuestionModal(),
                                    },
                                },
                            }}
                        // onClose={() => handleQuestionModal()}
                        />
                    </ModalLayout>

                    <ModalLayout ref={ModalLayoutSuccessRef} onClosed={refetch}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                backgroundColor: 'common.white',
                            }}
                        >
                            {/* <Box sx={{
                            "svg":{
                                width:"80px",
                                height:"80px",
                                color:"success.main"
                            }
                        }}>
                            <CheckCircle />
                        </Box> */}
                            <Box
                                sx={{
                                    mt: '-20px',
                                    svg: {
                                        width: '65px',
                                        height: '65px',
                                        color: 'success.main',
                                        backgroundColor: 'common.white',
                                        borderRadius: '100%',
                                    },
                                }}
                            >
                                <CheckCircle />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    p: 3,
                                    gap: 2,
                                    position: 'relative',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: 'success.main',
                                    }}
                                >
                                    {`قرعه کشی با موفقیت انجام شد!`}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        mt: .7,
                                        textAlign: "center"
                                    }}
                                >
                                    {SuccessLotteryApi?.count || 0} نفر در قرعه کشی برنده شدند!
                                </Typography>

                                {/* {true ? <Typography variant="h6">{'منتظر قرعه کشی باشید.'}</Typography> : ''} */}

                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={() => {
                                        handleSuccessModal();
                                        navigate(Enum.routes.root(paramsType, paramsTravelId));
                                    }}
                                >
                                    {'بستن'}
                                </Button>
                            </Box>
                        </Box>
                    </ModalLayout>

                    {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
                    <Box>
                        {/* {!isHistory ? (
                            <HeaderBreadcrumbs heading={data?.form?.name}>
                                <Typography>{data?.form?.travel?.name}</Typography>
                            </HeaderBreadcrumbs>
                        ) : (
                            ''
                        )} */}

                        <FormProvider methods={methods} onSubmit={handleSubmit(handleQuestionModal)}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 3,
                                    mb: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <RHFTextField name={"maxPersions"} label={'تعداد کل افراد'} />
                                    </Grid>

                                </Grid>
                            </Box>

                            {/* Form */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Grid sx={{}} container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'اطلاعات فردی'}</Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"name"}
                                            label={"نام"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"last_name"}
                                            label={"نام خانوادگی"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"shenasname"}
                                            label={"شماره شناسنامه"}
                                            type={"numbers"}
                                            disableComma
                                            withZero

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"codeMeli"}
                                            label={"کدملی"}
                                            type={"numbers"}
                                            disableComma
                                            withZero

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"father_name"}
                                            label={"نام پدر"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFDatePicker
                                            name={"fromBirthDate"}
                                            label={"ازتاریخ تولد"}
                                            pickerProps={{
                                                maxDate: new Date(),
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFDatePicker
                                            name={"toBirthDate"}
                                            label={"تا تاریخ تولد"}
                                            pickerProps={{
                                                maxDate: new Date(),
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFRadioGroups
                                            options={GENDER_DEFAULT_OP}
                                            name={"gender"}
                                            label={"جنسیت"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"email"}
                                            label={"پست الکترونیک"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"phone"}
                                            label={"تلفن همراه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={
                                                [
                                                    { label: "مجرد", value: "مجرد" },
                                                    { label: "متاهل", value: "متاهل" },
                                                ]
                                            }
                                            name={"marride_status"}
                                            label={"وضعیت تاهل"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={TASHAROF_DEFAULT_OP}
                                            name={"tashrof_be_sorat"}
                                            label={"تشرف به صورت"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={PASSPORT_DEFAULT_OP}
                                            name={"gozarnameh_status"}
                                            label={"وضعیت گذرنامه"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFDatePicker
                                            name={"marrid_date_from"}
                                            label={"ازتاریخ عقد"}
                                            pickerProps={{
                                                maxDate: new Date(),
                                            }}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFDatePicker
                                            name={"marrid_date_to"}
                                            label={"تا تاریخ عقد"}
                                            pickerProps={{
                                                maxDate: new Date(),
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "بله", value: "بله" },
                                                { label: "خیر", value: "خیر" }
                                            ]}
                                            name={"student_marriage"}
                                            label={"ازدواج دانشجویی"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={DUTY_DEFAULT_OP}
                                            name={"duty_system"}
                                            label={"وضعیت نظام وظیفه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "تشیع", value: "تشیع" },
                                                { label: "تسنن", value: "تسنن" }
                                            ]}
                                            name={"mazhab"}
                                            label={"مذهب"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "هستم", value: "هستم" },
                                                { label: "نیستم", value: "نیستم" }
                                            ]}
                                            name={"need_vam"}
                                            label={
                                                <>
                                                    <Typography component={"span"}>متقاضی دریافت</Typography>
                                                    <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                                </>
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "اعزام با دانشگاهیان", value: "اعزام با دانشگاهیان" },
                                                { label: "اعزام غیر دانشگاهی", value: "اعزام غیر دانشگاهی" },
                                                { label: "نداشته ام", value: "نداشته ام" },
                                            ]}
                                            name={"sabegh_tasharof"}
                                            label={"سابقه تشرف"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"home_phone"}
                                            label={"تلفن ثابت (به همراه کد)"}

                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "بله", value: "بله" },
                                                { label: "خیر", value: "خیر" }
                                            ]}
                                            name={"has_hamrah"}
                                            label={"اعزام با همراه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "1", value: "1" },
                                                { label: "2", value: "2" },
                                                { label: "3", value: "3" },
                                                { label: "4", value: "4" }
                                            ]}
                                            name={"hamrah_count"}
                                            label={"تعداد همراه"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'اطلاعات دانشگاهی'}</Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <ProvinceSelector.Element
                                            name={"provoince_university"}
                                            label={"استان محل تحصیل"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <universityCategorySelector.Element
                                            name={"type_university"}
                                            label={"نوع دانشگاه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UniversitySelector.Element
                                            name={"university_code"}
                                            // provinceId={getValues("provoince_university")?.value || undefined}
                                            // categoryId={getValues("type_university")?.value || undefined}
                                            province_name={getValues("provoince_university")?.label || undefined}
                                            category_name={getValues("type_university")?.label || undefined}
                                            label={"کد دانشگاه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mb:1}}>
                                            <Typography sx={{fontSize:15 , color:"#707070"}}>وضعیت شرکت در کنکور های سراسری سال جاری</Typography>
                                        </Stack> */}
                                        <RHFSelector
                                            options={[
                                                { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                                { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                                { label: "دوره دکتری", value: "دوره دکتری" },
                                                { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                                { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                            ]}
                                            name={"kunkor"}
                                            label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی", value: "کارشناسی" },
                                                { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                                { label: "دکتری", value: "دکتری" },
                                            ]}
                                            name={"degree"}
                                            label={"مقطع تحصیلی"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "هيئت علمي رسمي تمام وقت", value: "هيئت علمي رسمي تمام وقت" },
                                                { label: "هيئت علمي نيمه وقت", value: "هيئت علمي نيمه وقت" },
                                                { label: "حق التدريس", value: "حق التدريس" },
                                                { label: "پيماني", value: "پيماني" },
                                                { label: "رسمي-آزمايشي", value: "رسمي-آزمايشي" },
                                                { label: "ساير احکام", value: "ساير احکام" },
                                            ]}
                                            name={"type_of_employment"}
                                            label={"نوع حکم کارگزینی"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "استاد", value: "استاد" },
                                                { label: "استادیار", value: "استادیار" },
                                                { label: "دانشیار", value: "دانشیار" },
                                                { label: "حق التدریس", value: "حق التدریس" },
                                                { label: "مربی", value: "مربی" },
                                            ]}
                                            name={"type_of_employment_state"}
                                            label={"مرتبه دانشگاهی"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'قرعه کشی بر اساس اطلاعات همسر'}</Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"hamsar_name"}
                                            label={"نام"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"hamsar_last_name"}
                                            label={"نام خانوادگی"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"hamsar_code_melli"}
                                            label={"کدملی"}
                                            type={"numbers"}
                                            disableComma
                                            withZero

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"hamsar_father_name"}
                                            label={"نام پدر"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFRadioGroups
                                            options={GENDER_DEFAULT_OP}
                                            name={"hamsar_gender"}
                                            label={"جنسیت"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"hamsar_phone"}
                                            label={"تلفن همراه"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "بله", value: "بله" },
                                                { label: "خیر", value: "خیر" }
                                            ]}
                                            name={"hamsar_is_student"}
                                            label={"آیا همسر دانشجو است؟"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "هستم", value: "هستم" },
                                                { label: "نیستم", value: "نیستم" }
                                            ]}
                                            name={"hamsar_need_vam"}
                                            label={
                                                <>
                                                    <Typography component={"span"}>متقاضی دریافت</Typography>
                                                    <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                                </>
                                            }

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={DUTY_DEFAULT_OP}
                                            name={"hamsar_nezam_vazife_status"}
                                            label={"وضعیت نظام وظیفه"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={PASSPORT_DEFAULT_OP}
                                            name={"hamsar_passport_status"}
                                            label={"وضعیت گذرنامه"}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <ProvinceSelector.Element
                                            name={"hamsar_university_city"}
                                            label={"استان محل تحصیل"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <universityCategorySelector.Element
                                            name={"hamsar_university_category"}
                                            label={"نوع دانشگاه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UniversitySelector.Element
                                            name={"hamsar_university_name"}
                                            // provinceId={getValues("provoince_university")?.value || undefined}
                                            // categoryId={getValues("type_university")?.value || undefined}
                                            province_name={getValues("hamsar_university_city")?.label || undefined}
                                            category_name={getValues("hamsar_university_category")?.label || undefined}
                                            label={"کد دانشگاه"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mb:1}}>
                                            <Typography sx={{fontSize:15 , color:"#707070"}}>وضعیت شرکت در کنکور های سراسری سال جاری</Typography>
                                        </Stack> */}
                                        <RHFSelector
                                            options={[
                                                { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                                { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                                { label: "دوره دکتری", value: "دوره دکتری" },
                                                { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                                { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                            ]}
                                            name={"hamsar_konkoor"}
                                            label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی", value: "کارشناسی" },
                                                { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                                { label: "دکتری", value: "دکتری" },
                                            ]}
                                            name={"hamsar_education_level"}
                                            label={"مقطع تحصیلی"}

                                        />
                                    </Grid>



                                </Grid>

                            </Box>

                            {/* Form (END) */}


                            {/* Upload Excel */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 3,
                                    mt: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <RHFUploadSingleFile
                                            accept=".xls,.xlsx"
                                            name={"file"}
                                            title={'آپلود اکسل'}
                                            description={`
                                                فایل را بکشید و رها کنید یا روی عکس کلیک کنید.
                                                فرمت های قابل قبول: .xls .xlsx
                                            `}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* Upload Excel (END) */}

                            {
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 5,
                                    }}
                                >
                                    <LoadingButton loading={isLoading} type="submit" variant="contained" color={'success'}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography>{beforeRegistrant ? 'ویرایش اطلاعات' : 'ثبت اطلاعات'}</Typography>
                                            <ControlPoint />
                                        </Box>
                                    </LoadingButton>
                                </Box>
                            }
                            {isError ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        mt: 1,
                                    }}
                                >
                                    <FormHelperText error>{`لطفا تمام فیلد ها را با دقت پر نمایید`}</FormHelperText>
                                </Box>
                            ) : (
                                ''
                            )}
                        </FormProvider>
                    </Box>
                </Page>
            }
        </Box>
    );
};

export default RegistrantFormLottery;
