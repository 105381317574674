/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import { Box, Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../../components/WaitingBox/index';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import AddableFormLocation from './AddableFormLocation/index';
import AddableFormEducation from './AddableFormEducation/index';
import AddableFormMarried from './AddableFormMarried';
import { useState } from 'react';
import InputsCore from 'src/components/InputsCore';
import { formTypesObject } from 'src/enumeration';
import { newDate } from 'src/utils/dating';
import errorsText from 'src/utils/errorsText';
import { optionsSelect } from '../enum';
import RegistrantFormLottery from './registeryForm';

const Add = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.travel;
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}${Enum.routes.lottery}`;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root}`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const [FormItemsData, setFormItemsData] = useState({});
  const [companionSectionId, setCompanionSectionId] = useState();
  const [FormLoading, setFormLoading] = useState(true)

  const isAttabat = false;
  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.lotteryApi?.travel?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  const onErrorMutating = (error) => {
    console.log('* * * onErrorMutating :', { error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  const handleGetTravelformResponses = async (formId) => {
    try {
      const { data } = await axiosInstance.get(`${Enum?.lotteryApi?.form?.base}/${formId}`)
      // for (let i = 0; i < data?.sections?.length; i++) {
      //   const element = data?.sections[i];

      // }
      console.log({ data })
      return data?.sections
    } catch (error) {
      console.log(error)
    }
  }

  // ---------------------------------------
  const onSuccess = async (resData) => {
    console.log('* * * onSuccess', { resData });
    const forms = resData?.data?.forms;

    const validationsObject = {};
    const resetData = {};
    const companionInfo = {
      section: null,
      id: null,
      count: 0,
    };

    const caravanInfo = {
      companions: {
        name: '',
      },
      // marital:{
      //   name:''
      // },
      gender: {
        name: '',
      },
    };
    const allInputLabels = {};
    const inputIdsObject = {};

    const isBeforeRegistrant = false;

    if (Array.isArray(forms) && forms.length > 0) {
      try {
        // Collect all API results using Promise.all
        const formResponses = await Promise.all(forms.map((element) => handleGetTravelformResponses(element?.id)));
        const formsArr = formResponses.flat();



        console.log({
          formsArr,
          // inputIdsObject
        })

        setFormItemsData({
          sections:formsArr,
          travel : resData?.data
        });

        setFormLoading(false)

        // Log all the responses
        console.log('* * * All Form Responses:', formResponses);
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
    } else {
      console.warn('No forms found in the response');
    }
    console.log('* * * onSuccess', { resetData });
    // reset(resetData);
  };

  const dataById = useQueryCustom({
    name: `getById_${Enum?.lotteryApi?.travel?.base}_${paramId}`,
    url: getById,
    onSuccess: onSuccess,
    enabled: !!paramId,
  });

  const handleLottery = () => {
    navigate(baseUrl + '/list');
  };

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      [validation.fieldNames.name]: values[validation.fieldNames.name],
    };

    console.log('* * * onSubmit : ', { reqData, values });
    mutate(reqData);
  };
  console.log({ queryParams, dataById: dataById?.data?.data?.travel });
  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
      }}
    >

      {false ? <WaitingBox /> :
        <>
          <RegistrantFormLottery 
            {...{
              data:{
                form : FormItemsData,
                members : 0
              }
            }}
          />
        </>
      }
      {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ my: 3 }}>{'تعداد افراد شرکت کننده در قرعه کشی'}</Typography>



        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.aaa} label={'تعداد کل افراد'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.bbb} label={'تعداد افراد مورد نیاز'} />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        <Typography sx={{ my: 3 }}>{'نوع قرعه کشی'}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RHFSelector
                options={[
                  {
                    label: 'همه',
                    value: 1,
                  },
                ]}
                name={validation.fieldNames.ccc}
                label={'دسته قرعه کشی'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        <AddableFormLocation />

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />
        <AddableFormMarried />

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        <AddableFormEducation />

        <Box
          sx={{
            display: 'flex',
            mt: 6,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton>

          <LoadingButton
            sx={{
              minWidth: '220px',
            }}
            type="submit"
            variant="contained"
            color={'success'}
            loading={isLoading}
            onClick={handleLottery}
          >
            {'قرعه کشی'}
          </LoadingButton>
        </Box>
      </FormProvider> */}
    </Box>
  );
};

export default Add;
