import { Autocomplete, Box, Chip, CircularProgress, InputAdornment, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller, useFormContext } from 'react-hook-form';
import { Close } from '@mui/icons-material';

const RHFSelector = ({
  options = [],
  label,
  require,
  name,
  inputProps,
  InputProps,
  onChange,
  multiple,
  showing,
  absolute,
  disableClearable = true,
  defaultValue,
  required,
  ...other
}) => {
  const { control } = useFormContext();
  // console.log(`* * * RHFSelector ${label}: `, { options });
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        // console.log('* * * RHFSelector :', { field });
        const availableOptions = options?.filter((option) =>
          multiple
            ? !field?.value?.some((selected) => selected.value === option.value)
            : field.value?.value !== option.value
        );

        return showing ? (
          <Box>
            {label && (
              <Box
                className={[
                  'flex items-center  mb-2',
                  // error ? 'text-error-primary' : '',
                  // disabled ? 'opacity-50' : '',
                ].join(' ')}
              >
                <label htmlFor={name} className="leading-[20px] min-h-[20px] text-[14px] ">
                  {label}
                </label>
                {/* {required ? <span className="text-primary-main ml-2">{' *'}</span> : ''} */}
              </Box>
            )}
            <Box
              sx={{
                py: 2,
              }}
            >
              {field?.value?.label || '-'}
            </Box>
          </Box>
        ) : (
          <Autocomplete
            disableClearable={disableClearable}
            // disableClearable
            ref={field.ref}
            onBlur={field.onBlur}
            noOptionsText={'دیتایی یافت نشد !'}
            multiple={multiple}
            id={name}
            disabled={absolute}
            // disabledItemsFocusable
            // disableListWrap
            // disableCloseOnSelect
            // disablePortal
            {...(absolute ? { open: false } : '')}
            // getOptionDisabled={}
            // freeSolo
            loadingText={'لطفا منتظر بمانید . . .'}
            onChange={(event, newValue) => {
              // console.log({ newValue });
              if (absolute) return;
              if (multiple) newValue = newValue?.filter((x) => x.label || x.value);
              else newValue = newValue?.label || newValue?.value ? newValue : null;
              onChange?.(newValue);
              field.onChange(newValue);
            }}
            options={!absolute ? availableOptions : []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} key={option?.value} size="small" label={option.label} />
              ))
            }
            
            renderInput={(params) => {
              return (
                <TextField
                  label={
                    <>
                      {label}
                      {required ? <span className="text-primary-main">{' *'}</span> : ''}
                    </>
                  }
                  variant="outlined"
                  error={error}
                  helperText={error?.message}
                  {...params}
                  // onChange={(e) => {
                  //   e.preventDefault();
                  //   // alert();
                  //   return false;
                  // }}
                  InputProps={{
                    // disabled: absolute,
                    ...inputProps,
                    ...params.InputProps,
                    ...InputProps,

                    inputProps: {
                      ...params.inputProps,
                      ...(absolute && {
                        sx: { pointerEvents: 'none' },
                      }),
                    },

                    // inputProps: {
                    //   ...params?.InputProps?.inputProps,
                    //   ...inputProps?.inputProps,
                    // },
                    endAdornment: [params?.InputProps?.endAdornment, InputProps?.endAdornment],
                  }}
                />
              );
            }}
            // {...field}
            value={multiple ? field.value || defaultValue || [] : field.value || defaultValue || null}
            // defaultValue={defaultValue}
            {...other}
            // sx={{
            //   ...(absolute && { pointerEvents: 'none' }),
            // }}
          />
        );
      }}
    />
  );
};

export default RHFSelector;
