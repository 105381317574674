import { useMemo } from 'react';
import { RHFUploadSingleFile } from '../../../../components/hook-form';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputImage = ({ name, data, disabled, index, refetch, ...props }) => {
  // const options=useMemo(()=>{
  //     // const param= JSON.parse(data?.options||'{}');
  //     return {
  //         array: data?.options?.items?.map((x)=>({label:x.label,value:x.label})),
  //         data:data?.options
  //     }

  // },[data]);
  console.log('* * * * data?.need_confirm :', { aaa: data, index });
  return (
    !data?.options?.hideThisItem &&
    <RHFUploadSingleFile
      name={name}
      title={data?.label}
      required={data?.options?.required}
      description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
فرمت های قابل قبول: gif، JPG، PNG،`}
      disabled={disabled}
      // status={
      //   data?.need_confirm && (
      //     //  isHistory &&
      //     <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
      //   )
      // }
      absolute={
        data?.need_confirm &&
        props?.isHistory && (
          <AcceptOrRejectWithQuestionsWithReason
            {...data?.client_inputs?.[index || 0]}
            history={data?.old_client_inputs?.[index || 0]}
            type={data?.type}
            refetch={refetch}
          />
        )
      }
      {...props}
    />
  );
};

export default FormInputImage;
