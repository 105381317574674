import { Route, Routes, useParams } from 'react-router';
import Enum from './enum';
import AddCourse from './Add';
import buildAccess from 'src/permission/buildAccess';
import PermissionRoute from 'src/permission/permissionRoute';
import { travelTypeObject } from 'src/enumeration';
// import List from './List';

const CourseManagement = () => {
  const urlParams = useParams();

  const access = buildAccess(Enum.routes.name , travelTypeObject[urlParams.type]?.type);

  return (
    // <div>{'hiiii'}</div>
    <Routes>
      {/* <Route path={`${Enum.routes.list}`} element={<List />} /> */}
      <Route
        path={`${Enum.routes.editRoute}/:id`}
        element={<PermissionRoute element={<AddCourse access={access} />} permissions={access?.update} />}
      />
    </Routes>
  );
};

export default CourseManagement;
