import { Route, Routes } from 'react-router';
import { useParams } from 'react-router-dom';
import Enum from './enum';
import List from './List';
import Registrations from './Registrations';
import Lottery from './Lottery';
import Add from './Add';
import PermissionRoute from '../../permission/permissionRoute';
import buildAccess from '../../permission/buildAccess';
import { travelTypeObject } from '../../enumeration/index';

const VisitorManagement = () => {
  const urlParams = useParams();

  const access = buildAccess(Enum.routes.name, travelTypeObject[urlParams.type]?.type);

  return (
    <Routes>
      <Route
        path={`${Enum.routes.lottery}/*`}
        element={<PermissionRoute element={<Lottery access={access} />} permissions={access?.confirm} />}
      />
      <Route path={`/`} element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />} />
      <Route
        path={`/registrations`}
        element={<PermissionRoute element={<Registrations access={access} />} permissions={access?.read} />}
      />
      <Route
        path={`${Enum.routes.add}`}
        element={<PermissionRoute element={<Add access={access} />} permissions={access?.confirm} />}
      />
    </Routes>
  );
};

export default VisitorManagement;
