/* eslint-disable camelcase */
import { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useDebounce from '../../hooks/useDebounce';
import Enum from './enum';
import axiosInstance from '../../utils/axios';
import { useQueryCustom } from '../../utils/reactQueryHooks';
import RemoveNullObjectValue from '../../utils/RemoveNullObjectValue';
import RHFSelector from '../../components/hook-form/RHFSelector';

const convertor = {
  object: (x) =>
    x && {
      label: x?.name,
      // <div className="flex gap-3 items-center">
      // 	<img
      // 		className="rounded-full object-cover h-[45px] w-[45px] border border-primary-main"
      // 		src={import.meta.env.VITE_IMAGE_URL + x.image?.[0]?.location}
      // 	/>

      // 	<span>{x.name}</span>
      // </div>
      value: x?.id,
      data: x,
    },
  array: (x) => x?.map(convertor.object),
};

const Element = ({ queryParams, geById, log, provinceId , categoryId , category_name , province_name, ...props }) => {
  const { watch, setValue , getValues } = useFormContext();
  const watchData = watch(props.name);
  const validFetchById = !!(geById && !watchData?.value);
  console.log({ watchData, validFetchById, geById });
  // ----------------------------------------------------------------

  const [apiParams, setApiParams] = useState({
    page: 1,
    perPage: 100,
    search: '',
    category_id : undefined,
    province_id : undefined,
    category_name : undefined,
    province_name : undefined,
  });

  const { debounce } = useDebounce({ setDebounce: (x) => setApiParams((p) => ({ ...p, search: x })) });
  // ----------------------------------------------------------------

  const getApi = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${Enum?.api?.base}`, { params: RemoveNullObjectValue(params) });
  };
  
    const handleSuccessApi = (resData) => {
      const helpResData = resData?.data?.data;
      const findIndex = helpResData?.findIndex(item => (item?.name?.trim() === getValues(props.name) || item?.name?.trim() === getValues(props.name)?.label?.trim()))
      if(findIndex === -1){
        setValue(props.name, null);
      }
    }

  const resQuery = useQueryCustom({
    name: `${Enum?.api?.base}`,
    url: getApi,
    params: apiParams,
    //staleTime: 300000,
    onSuccess:handleSuccessApi
  });

  const getByIdApi = async () => {
    return axiosInstance.get(`${Enum?.api?.base}/${geById}`);
  };
  const resByIdQuery = useQueryCustom({
    name: `get_${Enum?.api?.base}_${props.name}`,
    url: getByIdApi,
    params: apiParams,
    enabled: validFetchById,
    onSuccess: (resData) => {
      console.log('* * * citySelector getByIdApi :', { resData, geById });
      setValue(props.name, convertor.object(resData?.data?.data));
    },
    //staleTime: 300000,
  });
  const defaultValue = validFetchById && convertor.object(resByIdQuery?.data?.data);
  // ----------------------------------------------------------------

  const inputProps = {
    onChange: (e) => debounce(e.target.value),
  };

  const options = useMemo(() => {
    return convertor.array(resQuery?.data?.data?.data);
  }, [resQuery]);

  // ----------------------------------------------------------------

  useEffect(() => {
    setApiParams((p) => ({ ...p, ...queryParams }));
  }, [queryParams]);
  
  useEffect(() => {
    setApiParams((p) => ({ ...p, province_id : provinceId }));
    console.log({provinceId})
  }, [provinceId]);

  useEffect(() => {
    setApiParams((p) => ({ ...p, category_id : categoryId }));
  }, [categoryId]);

  useEffect(() => {
    setApiParams((p) => ({ ...p, province_name : province_name }));
  }, [province_name]);

  useEffect(() => {
    setApiParams((p) => ({ ...p, category_name : category_name }));
  }, [category_name]);

  useEffect(() => {
    if (!watchData && apiParams?.search) {
      setApiParams((p) => ({ ...p, search: '' }));
    }
  }, [watchData]);
  // ----------------------------------------------------------------
  // console.log('* * * UniversitySelector onSuccess : ', { resQuery, options });

  // ----------------------------------------------------------------
  return (
    <RHFSelector
      defaultValue={defaultValue}
      options={options}
      inputProps={inputProps}
      loading={resQuery.isLoading}
      {...props}
    />
  );
};

const UniversitySelector = {
  Element,
  convertor,
};

export default UniversitySelector;
