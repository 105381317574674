import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import VisitorFilters from 'src/pages/visitorManagement/component/Filters';
import ShowFilters from 'src/pages/visitorManagement/component/ShowFilters';

import axiosInstance from '../../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import useQueryString from '../../../../../utils/useQueryString';
import Enum from '../../../enum';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Iconify from '../../../../../components/Iconify';
import TableComponent from '../../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../../hooks/useTable';
import FiltersCaravanList from './Filters';
import api from '../../../../../services/api';
import validation from './Filters/validation';
import WaitingBox from '../../../../../components/WaitingBox/index';
import SelectBed from '../SelectBed';
import axiosInstanceV2 from 'src/utils/axiosV2';

const List = () => {
  const [toggleModal, setToggleModal] = useState();

  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString({ sort: 'createdAt@desc' });
  const navigate = useNavigate();
  const [CustomFilterObj, setCustomFilterObj] = useState({})

  // ----------------------------------------------------------

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${queryParams.id}`);

  const caravanById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${queryParams.travel}_${queryParams.id}`,
    url: getById,
    enabled: !!queryParams.id,
  });

  const caravan = caravanById?.data?.data;
  // ----------------------------------------------------------
  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}/show/${queryParams.id}/${
    Enum.enumTab.object.client.value
  }`;

  // const getting = async () => {
  //   const province = queryString.province && JSON.parse(queryString.province || '');
  //   const city = queryString.city && JSON.parse(queryString.city || '');
  //   const university = queryString.university && JSON.parse(queryString.university || '');
  //   return axiosInstance.get(`${api.travelRegister.base}`, {
  //     params: {
  //       ...(province?.value && { [validation.fieldNames.province_id]: province.value }),
  //       ...(city?.value && { [validation.fieldNames.city_id]: city.value }),
  //       ...(university?.value && { [validation.fieldNames.university_id]: university.value }),
  //       ...(queryString.code_melli && { [validation.fieldNames.code_melli]: queryString.code_melli }),
  //       travel_id: queryParams.travel,
  //       status: 1,
  //     },
  //   });
  // };

  const getting = async () => {
    const { filters, ...others } = queryString;
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
        const stateFilters = CustomFilterObj?.filters

    const newFilters = {};
    for (const key in stateFilters) {
      if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
        const element = stateFilters[key];
        newFilters[key] = element?.value
      }
    }
    console.log('* * * getting : ', { newFilters , CustomFilterObj });
    return axiosInstanceV2.get(`${api.travelRegister?.base}/${queryParams.travel}`, {
      params: {
        ...others,
        // 'status[]': '1,2',
        status: [1, 2, 5],
        ...(newFilters && (newFilters) ),
        duty_status: 1,
        bank_status: 1,
        identity_status: 1,
      },
    });
  };

  // const getting = async () => {
  //   const { filters, ...others } = { ...queryString };

  //   const stateFilters = CustomFilterObj?.filters

  //   const newFilters = {};
  //   for (const key in stateFilters) {
  //     if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
  //       const element = stateFilters[key];
  //       newFilters[key] = element?.value
  //     }
  //   }

  //   console.log('* * * getting : ', { newFilters, filters, CustomFilterObj, stateFilters  , queryParams : queryParams.travel });

  //   return axiosInstanceV2.get(`${api.travelRegister?.base}/${queryParams.travel}`, {
  //     params: {
  //       ...others,
  //       status: [1, 2, 5],
  //       // 'status[]': 2,
  //       duty_status: 1,
  //       bank_status: 1,
  //       identity_status: 1,
  //       ...(newFilters && (newFilters)),
  //     },
  //   });
  // };

  const { themeStretch } = useSettings();

  const tableSetting = useTable({
    queryString,
    maxRowSelect: {
      count: (caravan?.capacity || 0) - (caravan?.filled_capacity || 0),
      error: 'ظرفیت پر شده است.',
    },

    // disabled: !!remainingCapacity && {
    //   rowSelect: { add: true },
    // },
  });

  const totalBedSelected = tableSetting?.selected?.reduce((prev, curr) => {
    return (prev += curr?.bed || 1);
  }, 0);

  const filledCapacity = caravan?.filled_capacity + (totalBedSelected || 0);
  const remainingCapacity = caravan?.capacity - filledCapacity;

  const remainingBed = useMemo(() => {
    let singleSelected = 0;
    let doubleSelected = 0;
    let tripleSelected = 0;

    tableSetting.selected?.forEach((x) => {
      if (x.bed === 3) tripleSelected += x?.members || 1;
      if (x.bed === 2) doubleSelected += x?.members || 1;
      if (x.bed === 1) singleSelected += x?.members || 1;
    });

    return {
      single: caravan?.remaining_single_bed - singleSelected,
      double: caravan?.remaining_double_bed - doubleSelected,
      triple: caravan?.remaining_triple_bed - tripleSelected,
    };
  }, [caravan, tableSetting.selected]);

  console.log({ tableSetting, totalBedSelected, queryParams, remainingBed, caravan });
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}_${queryParams.id}`,
    url: getting,
    params: { ...queryString },
  });

  const creating = (params) => axiosInstance.post(Enum.api.client, params);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    tableSetting.onResetSelectedRow();
    setToggleModal(false);
    // navigate(backUrl);
  };

  
  const customRefetch = (customParams = {}) => {
    console.log({customParams : customParams?.filters})
    // setCustomFilterObj(customParams?.filters)
    return refetch();
  };

  useEffect(() => {
    customRefetch();
  },[CustomFilterObj])

  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  const mutateAdd = useMutationCustom({
    url: creating,
    name: `${Enum?.api?.base}_update`,
    // invalidQuery: `${Enum?.api?.base}_get_${queryParams.travel}_${queryParams.id}`,
    invalidQueries: [
      `get_by_Id_${Enum?.api?.base}_${queryParams.travel}_${queryParams.id}`,
      `${Enum?.api?.base}_get_${queryParams.travel}_${queryParams.id}`,
    ],

    // invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  const onSubmit = async () => {
    console.log('* * * onSubmit : ', { tableSetting });
    // const objectId = data?.data?.reduce((prev, curr) => {
    //   prev[curr?.id] = curr;
    //   return prev;
    // }, {});
    const reqData = {
      team_id: queryParams.id,
    };
    tableSetting?.selected?.forEach((x, i) => {
      reqData[`clients[${i}][id]`] = x?.client?.id;
      reqData[`clients[${i}][bed]`] = x?.bed || 1;
      // if (objectId?.[x]?.client?.id) reqData[`clients[${i}][id]`] = objectId?.[x]?.client?.id;
    });

    const formData = new FormData();
    Object.keys(reqData || {})?.map((x) => {
      formData.append(x, reqData[x]);
    });
    // if (paramId) {
    //   formData.append('_method', 'put');
    // }

    console.log('* * * onSubmit : ', { reqData, tableSetting });
    mutateAdd.mutate(formData);
  };
  const handleToggleModal = () => setToggleModal((p) => !p);
  const handleSetModalData = (data) => setToggleModal(data);
  const handleSelectRow = (data) => {
    // if (!tableSetting.selectedObject?.[data.id] && data?.members <= 1) return handleSetModalData(data);
    if (!tableSetting.selectedObject?.[data.id]) {
      if (
        (data.members === 2 && !remainingBed.double) ||
        (data.members === 3 && !remainingBed.triple) ||
        data?.members < 1 ||
        data.members > 3
      ) {
        return handleSetModalData(data);
      }
    }
    tableSetting.onSelectRow({ ...data, bed: data.members });
  };
  const title = ` ${caravan?.name || ''} - افزودن زائر  `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root(queryParams.type, queryParams.travel)}`,
  });

  console.log({ tableOptions, data });

  return caravanById.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={`${title}`}>
      <Modal
        open={toggleModal}
        onClose={handleToggleModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <SelectBed
          {...{
            caravan,
            onClose: handleToggleModal,
            onAccept: (data) => {
              console.log('* * * onAccept :', { data });
              tableSetting.onSelectRow(data);
              handleToggleModal();
            },
            remainingBed,
            data: toggleModal,
          }}
          // onClose={handleQuestionModal}
        />
      </Modal>
      
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          back={backUrl}
          links={[
            { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
        />
        {/* <FiltersCaravanList /> */}
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            py: 1,
            backgroundColor: 'background.neutral',
            borderRadius: '8px',
          }}
        >
          <Typography>{'نتایج جستجو'}</Typography>
        </Box> */}
        <TableComponent
          active={{
            // selectable: true,

            selectable: {
              // find: (data) => data?.client,
              select: handleSelectRow,
            },
            rowNumber: true,
            filter: {
              component: <VisitorFilters {...{ queryString }} customRefetch={customRefetch} setCustomFilterObj={setCustomFilterObj} CustomFilterObj={CustomFilterObj}/>,
              show: <ShowFilters {...{ queryString }} CustomFilterObj={CustomFilterObj} setCustomFilterObj={setCustomFilterObj} stateMode />,
            },
          }}
          show={{
            filters: <ShowFilters {...{ queryString }} CustomFilterObj={CustomFilterObj} setCustomFilterObj={setCustomFilterObj} stateMode />,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          backgroundColor: 'background.neutral',
          borderRadius: '8px',
          borderTop: '1px solid',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                {'ظرفیت کاروان :'}
              </Typography>
              <Typography>{caravan?.capacity}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                {'تعداد تکمیل شد : '}
              </Typography>
              <Typography>{filledCapacity || 0}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                {'تخت تک نفره :'}
              </Typography>
              <Typography>{remainingBed?.single}</Typography>
            </Box>

            {/* ----------------- */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                {'تخت دو نفره :'}
              </Typography>
              <Typography>{remainingBed?.double}</Typography>
            </Box>

            {/* ----------------- */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                {'تخت سه نفره :'}
              </Typography>
              <Typography>{remainingBed?.triple}</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          {' '}
          <LoadingButton
            disabled={!tableSetting?.selected?.length}
            loading={mutateAdd?.isLoading}
            variant="contained"
            color={'success'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={onSubmit}
          >
            {`افزودن به کاروان`}
          </LoadingButton>
        </Box>
      </Box>
    </Page>
  );
};

export default List;
