import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { separateNumberWithComma } from '../../utils/index';
import persianToEnglishNumber from '../../utils/persianToEnglishNumber';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({
  name,
  type,
  min,
  max,
  text,
  showing,
  absolute,
  onChange,
  disableComma,
  isPhone,
  required,
  label,
  withZero,
  ...other
}) {
  const { control } = useFormContext();
  const isNumber = type === 'number';
  if (isNumber) type = 'string';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: change, value, ...field }, fieldState: { error } }) => {
        const changeValue = isNumber && !disableComma ? separateNumberWithComma(value) : value;
        // console.log(`RHFTextField - ${name}`, { changeValue });
        const handleChange = (e) => {
          if (absolute) return;
          if (isNumber) {
            let newValue = e.target.value?.replace(/,/g, '')?.trim();
            if (newValue)
              newValue = isPhone || withZero ? persianToEnglishNumber(newValue) : +persianToEnglishNumber(newValue);
            const regex = /^[0-9]+$/;
            // const regex = /^[0-9\u0660-\u0669\u06F0-\u06F9]+$/;
            console.log(`RHFTextField - ${name} - handleChange`, {
              value: e.target.value,
              disableComma,
              changeValue,
              newValue,
              min,
              max,
            });
            if (regex.test(newValue) && !Number.isNaN(newValue)) {
              if (min >= 0) {
                if (newValue < min) {
                  newValue = min;
                }
              }
              if (max >= 0) {
                if (newValue > max) {
                  newValue = max;
                }
              }
            }
            console.log(`RHFTextField - ${name} - handleChange`, {
              newValue,
            });
            onChange?.(newValue);
            change(newValue);
          } else {
            onChange?.(e);
            change(e);
          }
        };

        return showing ? (
          <Box>
            {label && (
              <Box
                className={[
                  'flex items-center  mb-2',
                  // error ? 'text-error-primary' : '',
                  // disabled ? 'opacity-50' : '',
                ].join(' ')}
              >
                <label htmlFor={name} className="leading-[20px] min-h-[20px] text-[14px] ">
                  {label}
                </label>
                {/* {required ? <span className="text-primary-main ml-2">{' *'}</span> : ''} */}
              </Box>
            )}
            <Box
              sx={{
                py: 2,
              }}
            >
              {value || '-'}
            </Box>
          </Box>
        ) : (
          <div>
            <TextField
              {...field}
              // inputProps={{
              //   max: 2,
              // }}
              label={
                // label + ' ' + required ? <span className="text-primary-main">{' *'}</span> : ''
                <>
                  {label}
                  {required ? <span className="text-primary-main">{' *'}</span> : ''}
                </>
              }
              value={changeValue}
              fullWidth
              error={!!error}
              helperText={error?.message}
              {...other}
              // sx={{
              //   ...(absolute && { pointerEvents: 'none' }),
              // }}

              onChange={handleChange}
              type={type}
              // pattern={isNumber ? '/^[0-9۰-۹]+$/' : undefined}
              onWheel={(e) => {
                // if (e.target.type === 'number') {
                //   // e.preventDefault();
                // }
                // e.target.blur();
                // setTimeout(() => e.target.focus(), 0);
              }}
            />
            {text ? <FormHelperText>{text}</FormHelperText> : ''}
          </div>
        );
      }}
    />
  );
}
