/* eslint-disable */
import { useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/utils/axios';
import { useQueryCustom } from 'src/utils/reactQueryHooks';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../../enum';
import { formTypesObject } from 'src/enumeration';
import InputsCore from './InputsCore';
import { optionsSelect } from 'src/pages/Form/enum';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import { fDateForApi, fDateTime } from 'src/utils/formatTime';
import WaitingBox from 'src/components/WaitingBox';
import createQueryString from 'src/utils/createQueryString';
import StaticFilterForm from '../staticFilter';

const VisitorFilters = ({ onClose, queryString , customRefetch , setCustomFilterObj , CustomFilterObj }) => {
  const [dataById, setDataById] = useState();
  // const [TravelApi, setTravelApi] = useState();

  const { t } = useTranslation();
  const queryParams = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: createQueryString(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  const methods = useForm({
    // resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;
  console.log({ errors, queryString });
  const onSubmit = () => {
    const values = getValues();
    const filterValues = RemoveNullObjectValue(values);
    const final = [];

    console.log('* * * onSubmit : ', { values, filterValues, dataById });

    Object.keys(filterValues || {})?.forEach((y) => {
      console.log('* * * onSubmit : ', { y });
      const currentY = filterValues[y];
      if (currentY) {
        let newVal = currentY?.value || currentY;

        try {
          if (newVal instanceof Date) {
            console.log('* * * onSubmit The value is a Date object.');
            newVal = fDateForApi(newVal);
          }
        } catch (error) {
          console.log({ error });
        }
        const [_, id] = y?.split('_');
        const current = dataById[id];
        const val = persianToEnglishNumber(newVal);
        final.push({
          label: current.label,
          type: current.type,
          input_id: id,
          value: val,
          title: currentY?.label,
        });
      }
    });

    console.log('* * * onSubmit : ', { final });
    navigateUrl({ filters: final?.length ? JSON.stringify(final) : undefined });
    onClose();
  };
  // ----------------------------
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/filters/${queryParams.travel}`);

  const onSuccess = (resData) => {
    const inputs = [...resData]; //?.data?.data;
    let filterObjectId = {};
    let filters;
    try {
      filters = JSON.parse(queryString?.filters || '');
      if (filters) {
        filters?.forEach((x) => (filterObjectId[x.input_id] = x));
      }
    } catch (error) {}

    console.log('* * * onSuccess', { resData, filters, filterObjectId });

    let parentProvinceName;
    let parentProvinceIndex;
    let byId = {};
    let resetValue = null;
    let resetData = {};
    let findItem;
    for (let j = 0; j < inputs.length; j += 1) {
      const input = inputs[j];
      const value = filterObjectId[input.id]?.value;
      // input.client_inputs = [{ value }];
      byId[input.id] = input;
      let options;
      try {
        options = input.options = JSON.parse(input.options || '{}');
      } catch (error) {
        options = input.options;
      }
      // console.log('* * * onSuccess', { options });

      inputs[j].options = options;
      if (options?.isProvince) {
        parentProvinceName = `${Enum.bseName + input.id}`;
        parentProvinceIndex = j;
      }
      if (options?.isCity && parentProvinceName) {
        inputs[j].parentProvinceName = parentProvinceName;
        inputs[parentProvinceIndex].childCityName = `${Enum.bseName + input.id}`;
      }

      switch (input?.type) {
        case formTypesObject.CHECKBOX.value:
          resetValue = value?.split(',')?.map((x) => ({ label: x?.trim(), value: x?.trim() }));
          // console.log('* * * dynamicly - CHECKBOX', input?.label, { value, findItem, resetValue }, options.required);
          break;

        case formTypesObject.RADIO.value:
          findItem = options?.items?.find((x) => value === x.label);
          resetValue = findItem && { label: findItem?.label, value: findItem?.label };
          // console.log('* * * dynamicly - RADIO', input?.label, { value, findItem, resetValue }, options.required);
          break;

        case formTypesObject.SELECT.value:
          optionsSelect?.map((x) => {
            if (x?.value && options[x.value]) {
              options.selectType = x;
            }
          });

          findItem = options?.items?.find((x) => value === x.label);
          resetValue = findItem && { label: findItem?.label, value: findItem?.label };
          // console.log('* * * dynamicly - SELECT', input?.label, { value, findItem, resetValue }, options.required);
          break;
        case formTypesObject.LOCATION.value:
          resetValue = value && { label: filterObjectId[input.id]?.title, value: filterObjectId[input.id]?.value };
          break;
        case formTypesObject.DATE.value:
          try {
            if (value) {
              console.log('* * * onSuccess DATE:', { value, date: new Date(value) });
              resetValue = new Date(value); //fDateTime(value);
            }
          } catch (error) {}
          break;
        default:
          resetValue = value;
          break;
      }
      resetData[`${Enum.bseName + input.id}`] = resetValue;
    }
    // console.log("* * * onSuccess :",{resetData});
    reset({ ...resetData });
    setDataById(byId);
    // setTravelApi(inputs);
    return inputs;
  };
  const data = useQueryCustom({
    name: `get_${Enum?.api?.base}_${queryParams.travel}`,
    url: getById,
    // onSuccess: onSuccess,
    enabled: !!queryParams.travel,
  });

  const resData = data?.data?.data?.data;

  const TravelApi = useMemo(() => {
    if (!resData?.length) return;
    return onSuccess(resData);
  }, [resData]);
  console.log({ data, TravelApi, dataById, resData });

  return (
    <Box
      sx={{
        width: '80%',
        height: `90vh`,
        overflow: 'auto',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <Box sx={{ mb: 3, p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'جستجوی پیشرفته'}</Box>
      <StaticFilterForm onClose={onClose} queryString={queryString} customRefetch={customRefetch} CustomFilterObj={CustomFilterObj} setCustomFilterObj={setCustomFilterObj} />
      {/* {data?.isLoading ? (
        <WaitingBox />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 3, p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'جستجوی پیشرفته'}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {/* <RHFTextField name={validation.fieldNames.name} label={'نام'} /> 
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            sx={{
              // mb:2,
              // backgroundColor: 'grey.main',
              p: 0,
            }}
            spacing={3}
          >
            {TravelApi?.map((x) => {
              let props = {
                xs: 12,
                md: 6,
              };

              if ([formTypesObject.IMAGE.value, formTypesObject.CHECKBOX.value].includes(x.type)) {
                props = {
                  xs: 12,
                  md: 12,
                };
              }
              // if (formTypesObject.LOCATION.value === x.type && x?.options?.isAddress) {
              //   props = {
              //     xs: 12,
              //     md: 12,
              //   };
              // }

              return (
                <Grid key={`${x.id}`} item {...props}>
                  <InputsCore
                    data={x}
                    // name={`name.firstname`}
                    name={`${Enum.bseName || ''}${x?.id}`}
                    // loading={loading}
                    // disabled={disabled}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Box
            sx={{
              display: 'flex',
              mt: 3,
              gap: 3,
              justifyContent: 'center',
            }}
          >
            <LoadingButton
              // type="click"
              variant="outlined"
              color="error"
              onClick={() => {
                navigateUrl({ filters: undefined });
                onClose();
              }}
            >
              {'پاک کردن'}
            </LoadingButton>
            <LoadingButton
              // type="click"
              variant="outlined"
              color="success"
              onClick={onClose}
            >
              {'بستن'}
            </LoadingButton>

            <LoadingButton type="submit" variant="contained" color={'success'}>
              {'انجام گزارش'}
            </LoadingButton>
          </Box>
        </FormProvider>
      )} */}
    </Box>
  );
};

export default VisitorFilters;
