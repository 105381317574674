import * as yup from 'yup';
import errorsText from '../../../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    reason: 'reason',
    status: 'status',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.reason]: yup.string().required(errorsText.blankError()),
    }),
};

export default validation;
