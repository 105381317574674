/* eslint-disable prefer-const */
import { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import useDebounce from 'src/hooks/useDebounce';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';

import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';

import validation from './validation';
// import RHFSelectStatic from '../../../components/hook-form/RHFSelectStatic';
import RHFSelector from '../../../components/hook-form/RHFSelector';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import { fDateApi, fDateForApi } from '../../../utils/formatTime';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import agentRoleSelector from '../../agentRole/selector';
import UniversitySelector from '../../university/selector';
import universityCategorySelector from '../../universityCategory/selector';
import ProvinceSelector from '../../../components/selector/Province';
import { caravanType, haveHasType, statusType, agentsType } from '../../../enumeration';
import RHFRadioGroups from '../../../components/hook-form/RHFRadioGroups';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';
import WaitingBox from '../../../components/WaitingBox/index';
import socialSelector from '../../social/selector';
import AddableFormSocial from './AddableFormSocial';
import agentSelector from '../../agent/selector';
import AddableFormAgents from './AddableFormAgents';
import FormInputs from '../FormInputs';

const Add = ({ onClose }) => {
  const defaultAgents = [
    // {
    //   agent: {
    //     value: agentsType.managerCaravan.value,
    //     label: 'مدیر کاروان',
    //   },
    //   admin: null,
    //   keyId: uniqueId(),
    // },
    // {
    //   agent: {
    //     value: agentsType.rohani.value,
    //     label: 'روحانی کاروان',
    //   },
    //   admin: null,
    //   keyId: uniqueId(),
    // },
  ];

  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const methods = useForm({
    // resolver: yupResolver(validation.schema()),
    // mode: 'onChange',
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      dispatch_date: new Date(),
      period_from: new Date(),
      period_to: new Date(),
      meeting_date: new Date(),
      agents: defaultAgents,
      men_count: 0,
      women_count: 0,
      married_count: 0,
    },

    // defaultValues: {
    //   active: 1,
    //   address: 'آدرس',
    //   baj_number: '12',
    //   border: 'سشیشسی',
    //   border_code: '23151',
    //   capacity: '10',
    //   deputy_id: 4,
    //   dispatch_date: '1403-03-16',
    //   flight_station: '123',
    //   interface_id: 4,
    //   iran_province_id: 6,
    //   is_lottery: 1,
    //   manager_id: 3,
    //   meeting_date: '1403-03-17',
    //   period_from: '1403-03-18',
    //   period_to: '1403-03-29',
    //   price: '200000',
    //   rohani_id: 4,
    //   storage_capacity: '2',
    //   storage_max: '15',
    //   team_grade: '10',
    //   team_type: 0,
    //   university_category_id: 3,
    //   university_id: 3,
    //   woman_rohani_id: 3,
    // },
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    trigger,
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.post(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccess = (resData) => {
    console.log('* * * onSuccess', { resData });

    let manager;
    let rohani;
    let deputy;
    let moeine;
    let interfaceId;

    // resData?.data?.agents?.forEach((x) => {
    //   if (x?.agent?.id === agentsType.managerCaravan.value) manager = agentSelector.convertor.object(x);
    //   if (x?.agent?.id === agentsType.rohani.value) rohani = agentSelector.convertor.object(x);
    //   if (x?.agent?.id === agentsType.deputyCaravan.value) deputy = agentSelector.convertor.object(x);
    //   if (x?.agent?.id === agentsType.moeine.value) moeine = agentSelector.convertor.object(x);
    //   if (x?.agent?.id === agentsType.interface.value) interfaceId = agentSelector.convertor.object(x);
    // });

    const resetData = {
      [validation.fieldNames.name]: resData?.data?.[validation.fieldNames.name],
      [validation.fieldNames.men_count]: resData?.data?.[validation.fieldNames.men_count],
      [validation.fieldNames.women_count]: resData?.data?.[validation.fieldNames.women_count],
      [validation.fieldNames.married_count]: resData?.data?.[validation.fieldNames.married_count],

      [validation.fieldNames.single_bed]: resData?.data?.[validation.fieldNames.single_bed],
      [validation.fieldNames.double_bed]: resData?.data?.[validation.fieldNames.double_bed],
      [validation.fieldNames.triple_bed]: resData?.data?.[validation.fieldNames.triple_bed],

      // [validation.fieldNames.manager_id]: manager,
      // [validation.fieldNames.rohani_id]: rohani,
      // [validation.fieldNames.deputy_id]: deputy,
      // [validation.fieldNames.woman_rohani_id]: moeine,
      // [validation.fieldNames.interface_id]: interfaceId,

      [validation.fieldNames.university_category_id]: universityCategorySelector.convertor.object(
        resData?.data?.university_category
      ),
      [validation.fieldNames.university_id]: UniversitySelector.convertor.object(resData?.data?.university),
      [validation.fieldNames.iran_province_id]: ProvinceSelector.convertor.object(resData?.data?.province),
      [validation.fieldNames.address]: resData?.data?.address,
      [validation.fieldNames.flight_station]: resData?.data?.flight_station,
      ...(resData?.data?.dispatch_date && {
        [validation.fieldNames.dispatch_date]: new Date(resData?.data?.dispatch_date),
      }),
      ...(resData?.data?.period_from && { [validation.fieldNames.period_from]: new Date(resData?.data?.period_from) }),
      ...(resData?.data?.period_to && { [validation.fieldNames.period_to]: new Date(resData?.data?.period_to) }),
      ...(resData?.data?.meeting_date && {
        [validation.fieldNames.meeting_date]: new Date(resData?.data?.meeting_date),
      }),
      // [validation.fieldNames.period_from]: new Date(resData?.data?.period_from),
      // [validation.fieldNames.period_to]: new Date(resData?.data?.period_to),
      // [validation.fieldNames.meeting_date]: new Date(resData?.data?.meeting_date),
      [validation.fieldNames.baj_number]: resData?.data?.baj_number,
      [validation.fieldNames.capacity]: resData?.data?.capacity,
      [validation.fieldNames.storage_capacity]: resData?.data?.storage_capacity,
      [validation.fieldNames.storage_max]: resData?.data?.storage_max,
      [validation.fieldNames.price]: resData?.data?.price,
      [validation.fieldNames.border]: resData?.data?.border,
      [validation.fieldNames.border_code]: resData?.data?.border_code,
      [validation.fieldNames.is_lottery]: haveHasType[resData?.data?.is_lottery],
      [validation.fieldNames.team_type]: caravanType[resData?.data?.team_type],
      [validation.fieldNames.team_grade]: resData?.data?.team_grade,
      [validation.fieldNames.active]: statusType[resData?.data?.active],
      [validation.fieldNames.socials]: !resData?.data?.socials?.length
        ? [{ keyId: uniqueId() }]
        : resData?.data?.socials?.map((x) => ({
          ...x,
          teamSocialId: x.id,
          social: { label: x.social.title, value: x.social.id },
          keyId: uniqueId(),
        })),
    };
    if (resData?.data?.agents?.length)
      resetData[validation.fieldNames.agents] = resData?.data?.agents?.map((x) => ({
        admin: agentSelector.convertor.object(x),
        agent: agentRoleSelector.convertor.object(x?.agent),
        keyId: uniqueId(),
      }));
    else resetData[validation.fieldNames.agents] = defaultAgents;

    console.log('* * * onSuccess', { resetData });
    reset(resetData);
    //
  };
  const dataById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${paramId}`,
    url: getById,
    onSuccess: onSuccess,
    enabled: !!paramId,
  });

  useEffect(() => {
    if (!dataById?.data?.data) return;
    console.log('* * * aaaaaaaa dataById: ');
    onSuccess(dataById?.data);
  }, [dataById?.data?.data]);

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });
    // const findAgents = values?.agents?.filter((agent) =>
    //   [agentsType.managerCaravan.value, agentsType.rohani.value].includes(agent[validation.fieldNames.agent]?.value)
    // );

    // if (findAgents?.length < 2) return;

    let reqData = {
      [validation.fieldNames.travel_id]: +queryParams.travel,
      [validation.fieldNames.name]: values[validation.fieldNames.name] ? values[validation.fieldNames.name] : undefined,
      // [validation.fieldNames.rohani_id]: values[validation.fieldNames.rohani_id]?.value,
      // [validation.fieldNames.deputy_id]: values[validation.fieldNames.deputy_id]?.value,
      // [validation.fieldNames.woman_rohani_id]: values[validation.fieldNames.woman_rohani_id]?.value,
      [validation.fieldNames.university_category_id]: values[validation.fieldNames.university_category_id]?.value ? values[validation.fieldNames.university_category_id]?.value : undefined,
      [validation.fieldNames.university_id]: values[validation.fieldNames.university_id]?.value ? values[validation.fieldNames.university_id]?.value : undefined,
      [validation.fieldNames.iran_province_id]: values[validation.fieldNames.iran_province_id]?.value ? values[validation.fieldNames.iran_province_id]?.value : undefined,
      [validation.fieldNames.address]: values[validation.fieldNames.address] ? values[validation.fieldNames.address] : undefined,
      [validation.fieldNames.flight_station]: values[validation.fieldNames.flight_station] ? persianToEnglishNumber(values[validation.fieldNames.flight_station]) : undefined,
      [validation.fieldNames.dispatch_date]: values[validation.fieldNames.dispatch_date] ? persianToEnglishNumber(
        fDateForApi(values[validation.fieldNames.dispatch_date])
      ) : undefined,
      [validation.fieldNames.period_from]: values[validation.fieldNames.period_from] ? persianToEnglishNumber(
        fDateForApi(values[validation.fieldNames.period_from])
      ) : undefined,
      [validation.fieldNames.period_to]: values[validation.fieldNames.period_to] ? persianToEnglishNumber(fDateForApi(values[validation.fieldNames.period_to])) : undefined,
      [validation.fieldNames.meeting_date]: values[validation.fieldNames.meeting_date] ? persianToEnglishNumber(
        fDateForApi(values[validation.fieldNames.meeting_date])
      ) : undefined,
      [validation.fieldNames.baj_number]: values[validation.fieldNames.baj_number] ? persianToEnglishNumber(values[validation.fieldNames.baj_number]) : undefined,
      [validation.fieldNames.capacity]: values[validation.fieldNames.capacity] ? persianToEnglishNumber(values[validation.fieldNames.capacity]) : 0,
      [validation.fieldNames.storage_capacity]: values[validation.fieldNames.storage_capacity] ? persianToEnglishNumber(values[validation.fieldNames.storage_capacity]) : 0,
      [validation.fieldNames.storage_max]: values[validation.fieldNames.storage_max] ? persianToEnglishNumber(values[validation.fieldNames.storage_max]) : undefined,
      [validation.fieldNames.price]: values[validation.fieldNames.price] ? persianToEnglishNumber(values[validation.fieldNames.price]) : undefined,
      [validation.fieldNames.border]: values[validation.fieldNames.border] ? values[validation.fieldNames.border] : undefined,
      [validation.fieldNames.border_code]: values[validation.fieldNames.border_code] ? persianToEnglishNumber(values[validation.fieldNames.border_code]) : undefined,
      [validation.fieldNames.is_lottery]: values[validation.fieldNames.is_lottery]?.value ? values[validation.fieldNames.is_lottery]?.value : undefined,
      [validation.fieldNames.team_type]: values[validation.fieldNames.team_type]?.value ? values[validation.fieldNames.team_type]?.value : undefined,
      [validation.fieldNames.team_grade]: values[validation.fieldNames.team_grade] ? values[validation.fieldNames.team_grade] : undefined,
      // [validation.fieldNames.interface_id]:
      //   values[validation.fieldNames.interface_id]?.value >= 0
      //     ? values[validation.fieldNames.interface_id]?.value
      // : values[validation.fieldNames.interface_id],
      [validation.fieldNames.status]: values[validation.fieldNames.active]?.value ? values[validation.fieldNames.active]?.value : undefined,
      [validation.fieldNames.active]: values[validation.fieldNames.active]?.value ? values[validation.fieldNames.active]?.value : undefined,
      [validation.fieldNames.men_count]: values[validation.fieldNames.men_count] ? values[validation.fieldNames.men_count] : 0,
      [validation.fieldNames.women_count]: values[validation.fieldNames.women_count] ? values[validation.fieldNames.women_count] : 0,
      [validation.fieldNames.married_count]: values[validation.fieldNames.married_count] ? values[validation.fieldNames.married_count] : 0,

      [validation.fieldNames.single_bed]: values[validation.fieldNames.single_bed] ? values[validation.fieldNames.single_bed] : 0,
      [validation.fieldNames.double_bed]: values[validation.fieldNames.double_bed] ? values[validation.fieldNames.double_bed] : 0,
      [validation.fieldNames.triple_bed]: values[validation.fieldNames.triple_bed] ? values[validation.fieldNames.triple_bed] : 0,
    };

    // [
    //   values[validation.fieldNames.manager_id]?.value,
    //   values[validation.fieldNames.rohani_id]?.value,
    //   values[validation.fieldNames.deputy_id]?.value,
    //   values[validation.fieldNames.woman_rohani_id]?.value,
    //   values[validation.fieldNames.interface_id]?.value,
    // ]
    //   ?.filter((x) => x)
    values?.[validation.fieldNames.agents]?.forEach((x, i) => {
      if (x) {
        reqData[`${validation.fieldNames.agents}[${i}][admin_id]`] = x?.admin?.value;
        // reqData[`${validation.fieldNames.agents}[${i}][team_admin_id]`] = x?.agent?.value;
      }
    });

    // [validation.fieldNames.socials]
    values[validation.fieldNames.socials]?.forEach((x, i) => {
      if (x.social?.value) {
        // reqData[validation.fieldNames.socials] = reqData[validation.fieldNames.socials] || [];
        reqData[`${validation.fieldNames.socials}[${i}][id]`] = x.social?.value;
        reqData[`${validation.fieldNames.socials}[${i}][link]`] = x?.link;
        reqData[`${validation.fieldNames.socials}[${i}][team_social_id]`] = x?.teamSocialId;
      }
    });
    console.log({ queryParams, travel_id: validation.fieldNames.travel_id, reqData });
    reqData = RemoveNullObjectValue(reqData);
    const formData = new FormData();
    Object.keys(reqData || {})?.map((x) => {
      let value = ![null].includes(reqData[x]) ? reqData[x] : '';
      formData.append(x, value);
    });
    if (paramId) {
      formData.append('_method', 'put');
    }



    console.log('* * * onSubmit : ', { reqData, values });
    mutate(formData);
  };
  console.log({ queryParams, dataById, errors });

  const { debounce } = useDebounce({
    setDebounce: () =>
      trigger([
        validation.fieldNames.men_count,
        validation.fieldNames.women_count,
        validation.fieldNames.married_count,
        validation.fieldNames.capacity,
      ]),
  });

  const triggerCount = () => {
    debounce();
  };

  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs
        back={`${Enum.routes.root(queryParams.type, queryParams.travel)}`}
        heading={
          paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
      >
        {/* <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> */}
      </HeaderBreadcrumbs>
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: 'grey.main',
                  borderRadius: '8px',
                }}
              >
                <Typography>اطلاعات کاروان </Typography>

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.name} label={'نام کاروان'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFRadioGroups
                options={Object.values(statusType)}
                name={validation.fieldNames.active}
                label={'فعال / غیر فعال'}
              />
            </Grid>
            <Grid item xs={12}>
              <AddableFormAgents />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <agentSelector.Element
                queryParams={{ agent_id: agentsType.managerCaravan.value }}
                name={validation.fieldNames.manager_id}
                label={'مدیر کاروان'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <agentSelector.Element
                queryParams={{ agent_id: agentsType.rohani.value }}
                name={validation.fieldNames.rohani_id}
                label={'روحانی کاروان'}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <agentSelector.Element
                // queryParams={{ agent_id: agentsType.rohani.value }}
                name={'aaaa'}
                label={'عوامل ها'}
                multiple
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <agentSelector.Element
                queryParams={{ agent_id: agentsType.deputyCaravan.value }}
                name={validation.fieldNames.deputy_id}
                label={'معاون کاروان'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <agentSelector.Element
                queryParams={{ agent_id: agentsType.moeine.value }}
                name={validation.fieldNames.woman_rohani_id}
                label={'روحانی زن'}
              />
            </Grid> */}
            
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: 'grey.main',
                  borderRadius: '8px',
                }}
              >
                <Typography>اطلاعات دانشگاهی</Typography>

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <universityCategorySelector.Element
                name={validation.fieldNames.university_category_id}
                label={'نوع دانشگاه'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UniversitySelector.Element name={validation.fieldNames.university_id} label={'دانشگاه'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProvinceSelector.Element name={validation.fieldNames.iran_province_id} label={'استان'} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: 'grey.main',
                  borderRadius: '8px',
                }}
              >
                <Typography>اطلاعات پروازی</Typography>

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.address} label={'آدرس کارگذار'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.flight_station} label={'ایستگاه پروازی'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFDatePicker name={validation.fieldNames.dispatch_date} label={'تاریخ اعزام'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFDatePicker name={validation.fieldNames.period_from} label={'بازه از تاریخ'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFDatePicker name={validation.fieldNames.period_to} label={'بازه تا تاریخ'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFDatePicker name={validation.fieldNames.meeting_date} label={'تاریخ جلسه کاروان'} />
            </Grid>

            
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.border} label={'مرز کاروان'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.border_code} label={'کد مرز'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.baj_number} label={'شماره باج'} />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: 'grey.main',
                  borderRadius: '8px',
                }}
              >
                <Typography>ظرفیت ها</Typography>

              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField
                onChange={triggerCount}
                name={validation.fieldNames.capacity}
                label={'ظرفیت'}
                type={'number'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.storage_capacity} label={'ظرفیت ذخیره'} type={'number'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.storage_max} label={'سقف ذخیره'} type={'number'} />
            </Grid>
           

{/* 
            <Grid item xs={12} md={6}>
              <RHFSelector
                options={Object.values(haveHasType)}
                name={validation.fieldNames.is_lottery}
                label={'قرعه کشی '}
              />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <RHFSelector
                options={Object.values(caravanType)}
                name={validation.fieldNames.team_type}
                label={'نوع کاروان'}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <RHFTextField name={validation.fieldNames.price} label={'قیمت'} type={'number'} />
            </Grid>

            <Grid item xs={12} md={4}>
              <RHFTextField
                onChange={triggerCount}
                type={'number'}
                name={validation.fieldNames.men_count}
                label={'تعداد آقایان'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <RHFTextField
                onChange={triggerCount}
                type={'number'}
                name={validation.fieldNames.women_count}
                label={'تعداد خانوم ها'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RHFTextField
                onChange={triggerCount}
                type={'number'}
                name={validation.fieldNames.married_count}
                label={'تعداد متاهلین'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <RHFTextField type={'number'} name={validation.fieldNames.single_bed} label={'تعداد تخت ۱ نفره'} />
            </Grid>
            <Grid item xs={12} md={4}>
              <RHFTextField type={'number'} name={validation.fieldNames.double_bed} label={'تعداد تخت ۲ نفره'} />
            </Grid>
            <Grid item xs={12} md={4}>
              <RHFTextField type={'number'} name={validation.fieldNames.triple_bed} label={'تعداد تخت ۳ نفره'} />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.team_grade} label={'درجه کاروان'} />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <agentSelector.Element
                queryParams={{ agent_id: agentsType.interface.value }}
                name={validation.fieldNames.interface_id}
                label={'رابط کاروان'}
              />
            </Grid> */}

            

            <Grid item xs={12}>
              <AddableFormSocial />
            </Grid>
          </Grid>
        </Box>

        {paramId ? <FormInputs inputs={dataById?.data?.data?.inputs} /> : ''}

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'ثبت اطلاعات'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default Add;
