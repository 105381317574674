import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import { FormProvider } from 'src/components/hook-form';
import validation from './validation';
import { useMutationCustom, useQueryCustom } from 'src/utils/reactQueryHooks';
import axiosInstance from 'src/utils/axios';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import WaitingBox from 'src/components/WaitingBox/index';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import api from 'src/services/api';
import { useEffect } from 'react';
import { acceptFinallyStatus, documentStatus } from 'src/enumeration';

const UserStatusInfo = ({ data }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;
  const travelId = queryParams?.course;
  // console.log({ queryParams,travelId });
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(api.changeStatus.base, params);
  const updating = (params) => axiosInstance.post(`${api.changeStatus.base}`, params);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${api.changeStatus.base}_update`,
    invalidQuery: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccess = (resData) => {
    console.log('* * * onSuccess', { resData });

    const resetData = {
      [validation.fieldNames.status]: acceptFinallyStatus[resData.status_num],
      [validation.fieldNames.bankStatus]: documentStatus[resData.bank_status_num],
      [validation.fieldNames.dutyStatus]: documentStatus[resData.duty_status_num],
      [validation.fieldNames.identityStatus]: documentStatus[resData.identity_status_num],
    };

    console.log('* * * onSuccess', { resetData });
    reset(resetData);
  };

  useEffect(() => {
    if (!data) return;
    onSuccess(data);
  }, [data]);
  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      travel_register_id: travelId,
      [validation.fieldNames.status]: values[validation.fieldNames.status]?.value,
      [validation.fieldNames.bankStatus]: values[validation.fieldNames.bankStatus]?.value,
      [validation.fieldNames.dutyStatus]: values[validation.fieldNames.dutyStatus]?.value,
      [validation.fieldNames.identityStatus]: values[validation.fieldNames.identityStatus]?.value,
    };

    console.log('* * * onSubmit : ', { reqData, values });
    mutate(reqData);
  };
  console.log({ queryParams });
  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.name} label={'نام'} />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.status}
                label={'وضعیت'}
                options={Object.values(acceptFinallyStatus)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.bankStatus}
                label={'وضعیت بانکی'}
                options={Object.values(documentStatus)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.dutyStatus}
                label={'نظام وظیفه'}
                options={Object.values(documentStatus)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.identityStatus}
                label={'مدارک هویتی'}
                options={Object.values(documentStatus)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'ثبت اطلاعات'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default UserStatusInfo;
